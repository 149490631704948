import t from 'react-translate';
import { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import NvIcon from 'shared/components/nv-icon';
import { config } from '@config/pendo.config.json';
import MentoringProgramContext from '../../context';
import MatchingRulesFlyoutModal from '../../modals/matching-rules-flyout-modal';
import MatchingRulesList from './matching-rules-list';

const MatchingRules = () => {
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: mentorshipProgramId } = mentoringProgram || {};
  const [showFlyoutModal, setShowFlyoutModal] = useState<boolean>(false);
  const translationBase = t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES;

  return (
    <>
      <div className='mb-4'>
        <div className='title heading-5 mb-2'>
          {translationBase.TITLE()}
        </div>
        <div className='description text-regular mb-2'>
          {translationBase.DESCRIPTION()}
        </div>
        <Button
          size='lg'
          onClick={() => setShowFlyoutModal(true)}
          variant='outline-primary'
          className='d-flex flex-row align-items-center'
          data-qa={config.pendo.athena.mentorshipProgram.settings.matchingRules.selectMatchingRules}
          data-qa-id={`${config.pendo.athena.mentorshipProgram.settings.matchingRules.selectMatchingRules}_${mentorshipProgramId}`}
          aria-label={translationBase.SELECT_MATCHING_RULES()}
        >
          <NvIcon icon='create-new-post' size='smallest' />
          <span className='ml-2'>
            {translationBase.SELECT_MATCHING_RULES()}
          </span>
        </Button>
      </div>
      {mentorshipProgramId && <MatchingRulesList />}
      {showFlyoutModal && (
        <MatchingRulesFlyoutModal onClose={() => setShowFlyoutModal(false)} />
      )}
    </>
  );
};

export default MatchingRules;
