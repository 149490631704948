
import { useContext } from 'react';
import { AngularContext } from 'react-app';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import {
  ReactComponent as ProgramClosedPicture,
} from 'styles/icons/program-closed.svg';
import { config } from '@config/pendo.config.json';

const IMAGE_HEIGHT = 150;
const IMAGE_WIDTH = 146.42;


const ProgramClosed = () => {
  const { injectServices } = useContext(AngularContext);
  const [$state, CurrentUserManager] = injectServices(['$state', 'CurrentUserManager']);

  const goToDashboard = () => {
    if (CurrentUserManager.isCourseAdminInAtLeastOneCourse() || CurrentUserManager.getProgramsAsAdminCount()) {
      $state.go('course-admin-dashboard', null, { replace: true });
    } else if (CurrentUserManager.isSupervisor() || CurrentUserManager.getProgramsAsMentorCount()) {
      $state.go('course-supervisor-dashboard', null, { replace: true });
    } else {
      $state.go('dashboard', null, { replace: true });
    }
  };

  const buttonLabel = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PROGRAM_CLOSED.BACK_TO_DASHBOARD();

  return (
    <div className='unreleased-section d-flex flex-column align-items-center justify-content-center'>
      <ProgramClosedPicture
        height={IMAGE_HEIGHT}
        width={IMAGE_WIDTH}
      />
      <div className='heading-4 mt-6 mb-1'>
        {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PROGRAM_CLOSED.TITLE()}
      </div>
      <div className='text-body-large description mb-6'>
        {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PROGRAM_CLOSED.DESCRIPTION()}
      </div>
      <Button
        size='lg'
        variant='primary'
        onClick={goToDashboard}
        data-qa={config.pendo.athena.mentorshipProgram.participantHome.redirectToDashboard.programClosed}
      >
        {buttonLabel}
      </Button>
    </div>
  );
};

export default ProgramClosed;
