import t from 'react-translate';
import { css } from '@emotion/react';
import ClickableContainer from 'components/clickable-container';
import NvIcon from 'shared/components/nv-icon';
import { gray2, gray6 } from 'styles/global_defaults/colors';
import NvTooltip from 'shared/components/nv-tooltip';
import { config } from '@config/pendo.config.json';
import { tooltipZIndex } from 'athena/components/constants';

type DeleteActionOptionProps = {
  actionItemId: number;
  onDelete: () => void;
};

const styles = css`
  &.delete-action-item {
    color: ${gray2};
    opacity: 0;
    flex-shrink: 0;
    padding: 8px;

    &:hover {
      background: ${gray6};
      border-radius: 50%;
    }
  }
`;

const DeleteActionOption = ({
  actionItemId,
  onDelete,
}: DeleteActionOptionProps) => {
  const dataQATag = config.pendo.athena.mentorshipProgram.participantHome.actionItems.item
    .deleteActionItem;

  const deleteLabel = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.ACTION_ITEMS.ITEM.TOOLTIP.DELETE_ACTION_ITEM();

  return (
    <NvTooltip
      placement='top'
      text={deleteLabel}
      zIndex={tooltipZIndex}
    >
      <ClickableContainer
        css={styles}
        onClick={onDelete}
        className='delete-action-item'
        aria-label={deleteLabel}
        data-qa={dataQATag}
        data-qa-id={`${dataQATag}-${actionItemId}`}
      >
        <NvIcon icon='trash' size='xss-smallest' />
      </ClickableContainer>
    </NvTooltip>
  );
};

export default DeleteActionOption;
