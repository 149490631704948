import React, { useMemo } from 'react';
import moment from 'moment';
import { AngularContext } from 'react-app';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { getFlyoutMyMentees } from 'redux/actions/courses';
import { BasicMentee } from 'redux/schemas/models/course';
import { getCurrentUserId } from 'redux/selectors/users';
import prodPathReplace from 'shared/prod-path-rewrite';
import { LoaderType } from 'shared/components/loading-placeholder';
import { useInfiniteLoading } from 'shared/hooks/use-infinite-loading';
import FlyoutContainer from './flyout-container';
import FlyoutCourseCard from './flyout-course-card';
import { AllLoaded, EmptyContent, LoadingBar } from './loading-placeholders';


enum OfferingTypes {
  COURSE = 'course',
  JOURNEY = 'journey',
  MENTORSHIP_PROGRAM = 'mentorship_program',
}

export const CourseMenteesPanel = () => {
  const { injectServices } = React.useContext(AngularContext);
  const [$state, InteroperabilityRoutes] = injectServices(['$state', 'InteroperabilityRoutes']);
  const { currentCatalogId, currentInstitutionId } = useSelector(state => state.app);
  const institutionBrandColor = useSelector(
    state => state.models.institutions[currentInstitutionId]?.brandColor,
  );
  const allCourses = useSelector(state => state.models.courses);
  const currentUserId = useSelector(getCurrentUserId);
  const { programId: currentProgramId } = $state.params;

  const {
    items,
    noItems,
    loading,
    loadingMore,
    hasMore,
    ids,
  } = useInfiniteLoading(
    'myMentees',
    'basicMentees',
    { page_size: 30 },
    getFlyoutMyMentees,
  );
  const myMentees = items as any; // BasicMentee[];

  return (
    <FlyoutContainer title={t.LHS.MY_MENTEES()} isHeaderSticky>
      <>
        {loading && <LoadingBar loaderType={LoaderType.FULL} />}
        {loadingMore && <LoadingBar loaderType={LoaderType.HALF} />}
        {!loading && noItems ? (
          <EmptyContent text={t.LHS.NO_MENTEES()} />
        ) : (
          ids.map((id, index) => {
            const {
              type,
              mentorshipProgramEnrollment,
              totalNoOfMentees,
              programAlias,
              menteeAlias,
              matchAnnouncementDate,
              catalogId,
              name,
              endDate,
              closeDate,
              headerBackground,
              thumbnail,
            } = myMentees[id];

            const isMentorshipProgram = type === OfferingTypes.MENTORSHIP_PROGRAM;

            const isClosed = isMentorshipProgram
              ? moment(endDate).isBefore(moment())
              : moment(closeDate).isBefore(moment());

            const numMentees = isMentorshipProgram
              ? mentorshipProgramEnrollment.assignedConnections.length
              : totalNoOfMentees;
            let showBadge = !!numMentees;
            if (isMentorshipProgram) {
              showBadge &&= matchAnnouncementDate && moment().isAfter(moment(matchAnnouncementDate));
            }
            const getMenteeAliasForProgram = () => (numMentees === 1 ? programAlias.mentee.singularizedTitleized : programAlias.mentee.pluralizedTitleized);
            const getMenteeAliasForCourse = () => (numMentees === 1 ? menteeAlias?.singularizedTitleized : menteeAlias?.pluralizedTitleized);
            const countSuffix = isMentorshipProgram
              ? getMenteeAliasForProgram()
              : getMenteeAliasForCourse();

            let link;
            if (isMentorshipProgram) {
              if (numMentees > 1) {
                link = $state.href('mentoring-program-my-mentees', { programId: id });
              }
              if (numMentees === 1) {
                link = $state.href('mentoring-program-single-connection', {
                  programId: id,
                  connectionId: mentorshipProgramEnrollment.assignedConnections[0].connectionId,
                });
              }
              link = $state.href('mentoring-program-root', { programId: id });
            }
            if (catalogId) {
              link = InteroperabilityRoutes.myMentees(allCourses[catalogId], currentUserId);
            }
            const isCurrent = isMentorshipProgram
              ? `${id}` === currentProgramId
              : (currentCatalogId === catalogId) && $state.includes('course-wrapper');

            return (
              <FlyoutCourseCard
                index={index}
                label={name}
                link={link}
                isCurrent={isCurrent}
                isClosed={isClosed}
                key={id}
                bgcolor={institutionBrandColor}
                image={isMentorshipProgram ? (headerBackground || prodPathReplace('images/mentoring-program-header-background-base-layer.png')) : thumbnail}
                tooltip={catalogId}
                variant='mentor'
                badgeLabel={showBadge ? `${numMentees} ${countSuffix}` : null}
                isMentorshipProgram={isMentorshipProgram}
              />
            );
          })
        )}
        {!hasMore && !noItems && <AllLoaded />}
      </>
    </FlyoutContainer>
  );
};

export default CourseMenteesPanel;
