import { css } from '@emotion/react';
import { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import { updateMentoringProgram, updateMentoringProgramHeader } from 'redux/actions/mentoring-programs';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import { almostBlack } from 'athena/styles/colors';
import NvDropdown, { NvDropdownButtonStyle, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import NvFilePicker from 'shared/components/nv-filepicker';
import NvTextArea from 'shared/components/inputs/nv-text-area';
import NvTooltip from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import { readFileAsDataURL } from 'shared/utils';
import { black, hexToRgbaString, white } from 'styles/global_defaults/colors';
import { boldFontWeight, headerFontSize, headerLargeLineHeight } from 'styles/global_defaults/fonts';
import { standardSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import DefaultHeaderBackground from './default-header-background';
import { getProgramDatesText } from './program-dates';


const FONT_COLORS = {
  WHITE: `${white}`,
  BLACK: `${almostBlack}`,
};

type EditProgramHeaderProps = {
  onClose: () => void;
};

const EditProgramHeader = ({
  onClose,
}: EditProgramHeaderProps) => {
  const dispatch = useAppDispatch();
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { endDate, headerBackground, headerColor, releasedDate } = mentoringProgram || {};

  type BackgroundImage = { file?: File; url: string; };
  const [backgroundImage, setBackgroundImage] = useState<BackgroundImage>({ file: null, url: headerBackground });
  const [programTitle, setProgramTitle] = useState<string>(mentoringProgram.name);
  const [fontColor, setFontColor] = useState<string>(headerColor || FONT_COLORS.WHITE);

  const onSelectFile = (file) => {
    readFileAsDataURL(file).then((src) => {
      setBackgroundImage({
        file,
        url: src,
      });
    });
  };

  const handleSave = () => {
    if (backgroundImage.file) {
      dispatch(updateMentoringProgramHeader({
        id: mentoringProgram.id,
        headerColor: fontColor,
        headerBackground: backgroundImage.file,
      }));
    } else {
      dispatch(updateMentoringProgram({
        id: mentoringProgram.id,
        headerColor: fontColor,
        ...(headerBackground !== backgroundImage.url ? { headerBackground: backgroundImage.url } : {}),
      }));
    }

    onClose();
  };

  const editBackgroundImageButton = () => (
    <div className='upload-bg-img d-flex align-items-center p-2'>
      <NvIcon icon='upload' size='smallest' className='mr-2' />
      {t.MENTORING_PROGRAMS.PROGRAM_HEADER_MENU.EDIT.EDIT_BACKGROUND_IMAGE()}
    </div>
  );

  const backgroundImageDropdownItems: NvDropdownOption[] = [
    {
      type: 'custom',
      customItem: (
        <NvFilePicker
          multiple={false}
          accept={['image/*']}
          onChange={([file]) => onSelectFile(file)}
          className='pl-3 py-1'
        >
          <div>{t.MENTORING_PROGRAMS.PROGRAM_HEADER_MENU.EDIT.UPLOAD_NEW_IMAGE()}</div>
        </NvFilePicker>
      ),
    },
    { type: 'divider' },
    {
      type: 'text',
      class: 'text-danger',
      callback: () => setBackgroundImage({ file: null, url: null }),
      text: t.MENTORING_PROGRAMS.PROGRAM_HEADER_MENU.EDIT.REMOVE_BACKGROUND_IMAGE(),
    },
  ];

  const styles = css`
    background-color: rgba(0, 0, 0, 0.9);
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 10;

    .program-form {
      position: absolute;
      z-index: 15;
      color: ${fontColor};
      height: 300px;
    }

    .upload-bg-img {
      background: ${hexToRgbaString(black, 0.3)};
      width: fit-content;
    }

    .program-title {
      background: ${hexToRgbaString(black, 0.3)};

      .title-textarea textarea {
        border: none;
        background: transparent;
        padding: 0;
        color: ${fontColor};

        font-size: ${headerFontSize}px;
        line-height: ${headerLargeLineHeight}px;
        font-weight: ${boldFontWeight};
      }
    }

    .text-color-picker {
      display: flex;
      justify-content: center;
      height: 16px;
      width: 100%;
      gap: ${standardSpacing}px;

      .circle {
        cursor: pointer;
        border-radius: 50%;
        border-style: solid;
        display: inline-block;
        width: 16px;
        height: 16px;
        border-width: 1px;
        transform: translateY(-8.5px);

        &.white-circle {
          border-color: black;
          background-color: white;
        }

        &.black-circle {
          border-color: white;
          background-color: black;
        }
      }
    }

    .button-row {
      gap: ${threeQuartersSpacing}px;
    }
  `;

  return (
    <div css={styles}>
      <div>
        <div className='program-form w-100'>
          {(backgroundImage.file || backgroundImage.url) ? (
            <NvDropdown
              buttonStyle={NvDropdownButtonStyle.CUSTOM}
              customTarget={editBackgroundImageButton}
              items={backgroundImageDropdownItems}
            />
          ) : (
            <NvFilePicker
              multiple={false}
              accept={['image/*']}
              onChange={([file]) => onSelectFile(file)}
            >
              <div className='upload-bg-img d-flex align-items-center p-2'>
                <NvIcon icon='upload' size='smallest' className='mr-2' />
                {t.MENTORING_PROGRAMS.PROGRAM_HEADER_MENU.EDIT.UPLOAD_A_BACKGROUND_IMAGE()}
              </div>
            </NvFilePicker>
          )}
          <div className='program-title mt-2 py-2 px-4'>
            <div>{releasedDate ? getProgramDatesText(releasedDate, endDate) : t.MENTORING_PROGRAMS.PROGRAM_START_DATE.NO_DATE_PLACEHOLDER()}</div>
            <NvTextArea
              value={programTitle}
              onChange={(e) => setProgramTitle(e.target.value)}
              className='title-textarea'
            />
          </div>
          <div className='text-color-picker'>
            <NvTooltip text={t.MENTORING_PROGRAMS.PROGRAM_HEADER_MENU.EDIT.TITLE_TEXT_WHITE()}>
              <div
                className='circle white-circle'
                onClick={() => setFontColor(FONT_COLORS.WHITE)}
              />
            </NvTooltip>
            <NvTooltip text={t.MENTORING_PROGRAMS.PROGRAM_HEADER_MENU.EDIT.TITLE_TEXT_BLACK()}>
              <div
                className='circle black-circle'
                onClick={() => setFontColor(FONT_COLORS.BLACK)}
              />
            </NvTooltip>
          </div>
        </div>
        <DefaultHeaderBackground
          headerBackground={backgroundImage?.url}
        />
      </div>
      <div className='button-row d-flex justify-content-center mt-4'>
        <Button variant='outline-light' onClick={onClose}>{t.FORM.CANCEL()}</Button>
        <Button variant='primary' onClick={handleSave}>{t.FORM.SAVE()}</Button>
      </div>
    </div>
  );
};

export default EditProgramHeader;
