import { useRef } from 'react';
import t from 'react-translate';
import { config } from '@config/pendo.config.json';
import NvTooltip from 'shared/components/nv-tooltip';
import CollapsibleRowMenu, {
  CollapsibleRowMenuButton,
  CollapsibleRowMenuDivider,
} from 'components/collapsible-row-menu';

type OptionsMenuProps = {
  canMoveUp: boolean;
  onMoveUp: () => void;
  canMoveDown: boolean;
  onMoveDown: () => void;
  onDelete: () => void;
};

const OptionsMenu = ({
  canMoveUp,
  onMoveUp,
  canMoveDown,
  onMoveDown,
  onDelete,
}: OptionsMenuProps) => {
  const rowMenuRef = useRef<HTMLDivElement>();
  const translationBase = t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS;

  const swapRowMenu = (action: string) => {
    const [callback, siblingProp] = action === 'move-up'
      ? [onMoveUp, 'previousSibling']
      : [onMoveDown, 'nextSibling'];

    const currentTrigger = rowMenuRef.current.querySelector('.icon-in-progress')?.parentElement;
    const sibling = rowMenuRef.current.closest('form')[
      siblingProp
    ] as HTMLFormElement;
    const siblingTrigger = sibling.querySelector('.icon-in-progress')?.parentElement;

    callback();
    // Collapse the current row menu
    currentTrigger?.click();
    // Expand the row menu of the new position
    siblingTrigger?.click();
  };

  return (
    <CollapsibleRowMenu
      className='self-centered'
      ref={rowMenuRef}
      ariaLabel={translationBase.ARIA_LABEL.PROGRAM_QUESTION_OPTIONS_MENU()}
    >
      <>
        <CollapsibleRowMenuButton
          icon='trash'
          onClick={onDelete}
          data-qa={
            config.pendo.athena.mentorshipProgram.settings.programProfileSetup
              .deleteProgramLevelProfileQuestion
          }
          aria-label={translationBase.ARIA_LABEL.DELETE_PROGRAM_LEVEL_QUESTION()}
        />
        <CollapsibleRowMenuDivider />
        <NvTooltip
          text={translationBase.DISABLED_STATE.MOVE_UP_DOWN()}
          enabled={!canMoveDown && !canMoveUp}
          placement='top'
          maxWidth={240}
        >
          <div className='d-flex'>
            <CollapsibleRowMenuButton
              icon='move-up'
              onClick={() => {
                swapRowMenu('move-up');
              }}
              disabled={!canMoveUp}
              data-qa={
                config.pendo.athena.mentorshipProgram.settings
                  .programProfileSetup.updateProgramLevelProfileQuestion
              }
              aria-label={translationBase.ARIA_LABEL.MOVE_PROGRAM_LEVEL_QUESTION_UP()}
            />
            <CollapsibleRowMenuButton
              icon='move-down'
              onClick={() => {
                swapRowMenu('move-down');
              }}
              disabled={!canMoveDown}
              data-qa={
                config.pendo.athena.mentorshipProgram.settings
                  .programProfileSetup.updateProgramLevelProfileQuestion
              }
              aria-label={translationBase.ARIA_LABEL.MOVE_PROGRAM_LEVEL_QUESTION_DOWN()}
            />
          </div>
        </NvTooltip>
        <CollapsibleRowMenuDivider />
      </>
    </CollapsibleRowMenu>
  );
};

export default OptionsMenu;
