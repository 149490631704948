import { css, SerializedStyles } from '@emotion/react';
import prodPathReplace from 'shared/prod-path-rewrite';
import { black, gray1, hexToRgbaString } from 'styles/global_defaults/colors';


type DefaultHeaderBackgroundProps = {
  extraStyles?: SerializedStyles;
  headerBackground?: string;
};

const DefaultHeaderBackground = ({
  extraStyles,
  headerBackground,
}: DefaultHeaderBackgroundProps) => {
  const styles = css`
    position: relative;
    height: 300px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      /* object-position: 0 -450px; */
    }

    .middle-layer {
      background-color: ${hexToRgbaString(black, 0.1)};
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    .top-layer {
      background: linear-gradient(180deg, ${gray1} 0%, ${hexToRgbaString(gray1, 0)} 100%);
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    ${extraStyles}
  `;

  return (
    <div css={styles}>
      <img src={headerBackground || prodPathReplace('images/mentoring-program-header-background-base-layer.png')} alt='' />
      <div className='middle-layer' />
      <div className='top-layer' />
    </div>
  );
};

export default DefaultHeaderBackground;
