import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import t from 'react-translate';
import { css, SerializedStyles } from '@emotion/react';

import { AngularContext } from 'react-app';

import { Course } from 'redux/schemas/models/course';
import { Institution } from 'redux/schemas/models/institution';
import tinycolor from 'tinycolor2';

import prodPathReplace from 'shared/prod-path-rewrite';
import NvIcon from 'shared/components/nv-icon';
import { NvTableCellProps, NvTableRowProps } from 'shared/components/nv-responsive-table';
import { createGridStyles, halfSpacing, quarterSpacing, standardSpacing, largeSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { boldFontWeight, textSmallFontSize, textSmallLineHeight } from 'styles/global_defaults/fonts';
import { gray3, gray5, primary, warning, white } from 'styles/global_defaults/colors';
import { isHandheld } from 'styles/global_defaults/media-queries';

import { CompletionRateProgress } from 'institutions/components/course-row';
import Badge from 'cohort_management/components/badge';
import NvBadge from 'shared/components/nv-badge';

import { SortAttr } from './types';


enum OfferingTypes {
  COURSE = 'course',
  JOURNEY = 'journey',
  MENTORSHIP_PROGRAM = 'mentorship_program',
}

const getOfferingCoverImage = (offering: any /* Course */) => {
  if (offering.type === OfferingTypes.MENTORSHIP_PROGRAM) {
    return offering.headerBackground || prodPathReplace('images/mentoring-program-header-background-base-layer.png');
  }

  const courseCoverImage = offering.thumbnail && !offering.thumbnail.includes('temp.png') ? offering.thumbnail : '';

  const journeyCoverImage = offering.headerBackground ?? offering.logo?.url;

  return offering.isJourney ? journeyCoverImage : courseCoverImage;
};

const isWhite = (color: string) => tinycolor(color).toHex() === tinycolor(white).toHex();

const getOfferingAvatarColor = (offering: Course, institution: Institution, coverImg?: string) => {
  const courseAvatarColor = coverImg ? '' : offering.headerColor ?? institution.brandColor;

  const isOfferingHeaderWhite = isWhite(offering.headerColor);
  const isInstitutionHeaderWhite = isWhite(institution.brandColor);

  const headerWhiteFallback = isInstitutionHeaderWhite ? gray3 : institution.brandColor;

  const journeyAvatarColor = isOfferingHeaderWhite ? headerWhiteFallback : offering.headerColor;

  return offering.isJourney ? journeyAvatarColor : courseAvatarColor;
};

const commonStyles = css`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const nameCellStyles = css`
  ${commonStyles}
  flex-direction: row;
  justify-content: 'center';
  grid-column: 1;
  padding: ${standardSpacing}px;
  gap: ${halfSpacing}px;

  .cover-image-container {
    width: 100px;
    min-width: 100px;
    height: 60px;
    pointer-events: all;
    position: relative;

    .course-cover-img:hover {
      cursor: pointer;
    }
  }

  .course-name-container {
    display: flex;
    flex-direction: column;
    gap: ${quarterSpacing}px;
    font-size: ${textSmallFontSize}px;
    line-height: ${textSmallLineHeight}px;
    overflow: hidden;

    .course-name {
      font-weight: ${boldFontWeight};

      &:hover {
        color: ${primary};
      }
    }

    .nv-badge {
      height: ${standardSpacing}px;
    }
  }
`;

const courseCardStyles = css`
  display: flex;
  gap: ${halfSpacing}px;
  padding: ${standardSpacing}px ${halfSpacing}px;
  border-bottom: 1px solid ${gray5};
  z-index: 2;

  &:hover {
    border-bottom: 1px solid ${warning};
  }

  .cover-image-container {
    width: 100px;
    min-width: 100px;
    height: 60px;
    pointer-events: all;
    position: relative;

    .course-cover-img:hover {
      cursor: pointer;
    }
  }

  .course-container {
    display: flex;
    flex-direction: column;
    gap: ${quarterSpacing}px;

    .course-name {
      font-size: ${textSmallFontSize}px;
      font-weight: ${boldFontWeight};

      &:hover {
        color: ${primary};
      }
    }

    .release-and-enrollees {
      padding: ${quarterSpacing}px 0;
      display: flex;
      flex-direction: column;
      gap: ${halfSpacing}px;
    }

    .completion {
      margin-top: ${standardSpacing}px;
    }
  }
`;

export type CourseRowExtraProps = {
  institution: Institution,
  selectedSortAttr: SortAttr,
};

type CourseCellProps = NvTableCellProps<Course, unknown, CourseRowExtraProps> & {
  course: any, // Course,
  // eslint-disable-next-line react/no-unused-prop-types
  institution: Institution,
  selectedSortAttr: SortAttr,
};

const CourseRow = (props: NvTableRowProps<Course, {}, CourseRowExtraProps>) => {
  const cells: [string, (props: CourseCellProps) => JSX.Element, SerializedStyles][] = [
    ['name-cell', NameCell, nameCellStyles],
    ['sort-date-cell', SortDateCell, commonStyles],
    ['num-enrollees-cell', NumEnrolleesCell, commonStyles],
    ['completion-rate-cell', CompletionRateCell, commonStyles],
  ];

  const [showMobileView, setShowMobileView] = useState(isHandheld());

  useEffect(() => {
    function handleResize() {
      setShowMobileView(isHandheld());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      {showMobileView ? (
        <CourseCard
          {...props}
          reactKey={`${props.rowIndex}-mobile`}
          serializedStyles={courseCardStyles}
          divProps={{ style: createGridStyles(1, props.rowIndex, 2, props.rowIndex + 1) }}
          course={props.data}
          institution={props.extraProps.institution}
          selectedSortAttr={props.extraProps.selectedSortAttr}
        />
      ) : (
        cells.map((Cell, i) => {
          const [key, Component, styles] = Cell;
          const cellProps: CourseCellProps = {
            ...props,
            reactKey: `${props.rowIndex}-${i + 1}`,
            serializedStyles: styles,
            divProps: {
              style: createGridStyles(i + 1, props.rowIndex, i + 2, props.rowIndex + 1),
            },
            course: props.data,
            institution: props.extraProps.institution,
            selectedSortAttr: props.extraProps.selectedSortAttr,
          };

          return <Component key={key} {...cellProps} />;
        })
      )}
    </React.Fragment>
  );
};

const badgeStyles = (avatarColor) => css`
  display: flex;
  gap: ${quarterSpacing}px;
  padding: 2px ${halfSpacing}px;
  color: ${white};
  background-color: ${avatarColor};
  border-radius: ${threeQuartersSpacing}px;
  font-size: ${textSmallFontSize}px;
  font-weight: ${boldFontWeight};
  width: fit-content;
`;

type CourseCoverImgProps = {
  catalogId: string;
  link: string;
  coverImg: string;
  avatarColor: string;
  isPrimary: boolean;
  isCohort: boolean;
  isMentorshipProgram: boolean;
  userCourse: number;
};

const CourseCoverImg = ({
  catalogId,
  link,
  coverImg,
  avatarColor,
  isPrimary,
  isCohort,
  isMentorshipProgram,
  userCourse,
}: CourseCoverImgProps) => {
  const styles = css`
    .cover-image {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      background-image: url(${coverImg});
      background-color: ${avatarColor};
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      ${isMentorshipProgram ? css`
        border-radius: ${quarterSpacing}px;
      ` : ''}
    }

    .mentor-plus-diamond {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 25px;
      width: 25px;
      background-color: ${primary};
      transform: rotate(45deg);
      border-top-left-radius: ${quarterSpacing}px;
      border-bottom-right-radius: ${quarterSpacing}px;
      box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.1), 0px 3px 2px rgba(0, 0, 0, 0.1);
    }
  `;

  return (
    <div css={styles} className='cover-image-container'>
      <a
        target='_blank'
        href={link}
        rel='noopener noreferrer'
        className='course-cover-img'
        data-offering-name={catalogId}
      >
        <div className='cover-image'>
          {isMentorshipProgram && (
            <div className='mentor-plus-diamond'>
              <NvIcon icon='mentor-plus' size='smallest' className='text-white' />
            </div>
          )}
        </div>
        <Badge
          course={{
            isPrimary,
            isCohort,
            userCourse,
          } as Course}
        />
      </a>
    </div>
  );
};

const NameCell = (props: CourseCellProps) => {
  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);

  const { course, institution } = props;

  let url = $state.href(
    course.isJourney ? 'learning-journey-home' : 'course-home',
    {
      catalogId: course.catalogId,
    },
  );
  if (course.type === OfferingTypes.MENTORSHIP_PROGRAM) {
    if (course.mentorshipProgramEnrollment.assignedConnections.length > 1) {
      url = $state.href('mentoring-program-my-mentees', { programId: course.id });
    } else if (course.mentorshipProgramEnrollment.assignedConnections.length === 1) {
      url = $state.href('mentoring-program-single-connection', {
        programId: course.id,
        connectionId: course.mentorshipProgramEnrollment.assignedConnections[0].connectionId,
      });
    } else {
      url = $state.href('mentoring-program-root', { programId: course.id });
    }
  }

  const coverImg = getOfferingCoverImage(course);
  const avatarColor = getOfferingAvatarColor(course, institution, coverImg);

  return (
    <div className='name-cell' css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      <CourseCoverImg
        catalogId={course.catalogId}
        link={url}
        coverImg={coverImg}
        avatarColor={avatarColor}
        isPrimary={course.isPrimary}
        isCohort={course.isCohort}
        isMentorshipProgram={course.type === OfferingTypes.MENTORSHIP_PROGRAM}
        userCourse={course.userCourse}
      />
      <div className='course-name-container'>
        {course.isJourney && (
          <div className='course-journey-badge' css={badgeStyles(avatarColor)}>
            <NvIcon icon='path' size='xss-smallest' />
            {t.OFFERINGS.CARD.BADGE.LEARNING_JOURNEY()}
          </div>
        )}
        {!!course.archivedAt && (
          <NvBadge className='nv-badge font-weight-bolder text-gray-1'>
            {t.COURSES.ARCHIVE.BADGE()}
          </NvBadge>
        )}
        <div className='course-name'>
          <h2 className='course-title-xxs mb-0 font-weight-bolder mt-0'>
            <a
              href={url}
              target='_blank'
              rel='noopener noreferrer'
              data-offering-name={course.catalogId}
            >
              {course.name}
            </a>
          </h2>
        </div>
        {course.type !== OfferingTypes.MENTORSHIP_PROGRAM && (
          <div className='course-catalog-id'>
            {course.catalogId}
          </div>
        )}
      </div>
    </div>
  );
};

const SortAttrPropNameMap: { [key in SortAttr]: string } = {
  [SortAttr.LAST_ACTIVITY_DATE]: 'lastActiveDate',
  [SortAttr.RELEASE_DATE]: 'releaseDate',
  [SortAttr.START_DATE]: 'officialReleaseDate',
  [SortAttr.END_DATE]: 'endDate',
  [SortAttr.CLOSE_DATE]: 'closeDate',
};

const MentorshipProgramSortAttrPropNameMap: { [key in SortAttr]: string } = {
  // last_activity_date, start_date, and end_date are not applicable for mentorship programs
  // Note that the property `end_date` does exist, but it mirrors a course's `close_date` property
  // Also note that `release_date` is actually spelled `released_date` for programs
  [SortAttr.LAST_ACTIVITY_DATE]: '',
  [SortAttr.RELEASE_DATE]: 'releasedDate',
  [SortAttr.START_DATE]: '',
  [SortAttr.END_DATE]: '',
  [SortAttr.CLOSE_DATE]: 'endDate',
};

const SortDateCell = (props: CourseCellProps) => {
  const { course, selectedSortAttr } = props;

  let sortDate = course.lastActiveDate;
  if (course.type === OfferingTypes.MENTORSHIP_PROGRAM) {
    if (!MentorshipProgramSortAttrPropNameMap[selectedSortAttr]) {
      return (
        <div className='sort-date-cell' css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
          <div className='font-weight-bold'>{t.COURSE_ADMIN_DASHBOARD.NOT_APPLICABLE()}</div>
        </div>
      );
    }
    sortDate = course[MentorshipProgramSortAttrPropNameMap[selectedSortAttr]];
  } else {
    sortDate = course[SortAttrPropNameMap[selectedSortAttr]];
  }

  return (
    <div className='sort-date-cell' css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      {sortDate && moment(sortDate).format('MM/DD/YYYY')}
    </div>
  );
};

const NumEnrolleesCell = (props: CourseCellProps) => {
  const { course } = props;

  return (
    <div className='num-enrolled-cell' css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      {course.numEnrolled ?? course.enrolledUsersCount ?? 0}
    </div>
  );
};

const CompletionRateCell = (props: CourseCellProps) => {
  const { course } = props;

  if (course.type === OfferingTypes.MENTORSHIP_PROGRAM) {
    return (
      <div className='completion-date-cell' css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
        <div className='font-weight-bold'>{t.COURSE_ADMIN_DASHBOARD.NOT_APPLICABLE()}</div>
      </div>
    );
  }

  return (
    <div className='completion-date-cell' css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      <CompletionRateProgress course={course} />
    </div>
  );
};

const CourseCard = (props: CourseCellProps) => {
  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);

  const { course, institution, selectedSortAttr } = props;

  let url = $state.href('course-home', {
    catalogId: course.catalogId,
  });
  if (course.type === OfferingTypes.MENTORSHIP_PROGRAM) {
    if (course.mentorshipProgramEnrollment.assignedConnections.length > 1) {
      url = $state.href('mentoring-program-my-mentees', { programId: course.id });
    } else if (course.mentorshipProgramEnrollment.assignedConnections.length === 1) {
      url = $state.href('mentoring-program-single-connection', {
        programId: course.id,
        connectionId: course.mentorshipProgramEnrollment.assignedConnections[0].connectionId,
      });
    } else {
      url = $state.href('mentoring-program-root', { programId: course.id });
    }
  }

  const coverImg = getOfferingCoverImage(course);
  const avatarColor = getOfferingAvatarColor(course, institution, coverImg);

  const sortDate = selectedSortAttr ? course[SortAttrPropNameMap[selectedSortAttr]] : course.releaseDate;

  return (
    <div css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      <CourseCoverImg
        catalogId={course.catalogId}
        link={url}
        coverImg={coverImg}
        avatarColor={avatarColor}
        isPrimary={course.isPrimary}
        isCohort={course.isCohort}
        isMentorshipProgram={course.type === OfferingTypes.MENTORSHIP_PROGRAM}
        userCourse={course.userCourse}
      />
      <div className='course-container'>
        {course.isJourney && (
          <div className='course-journey-badge' css={badgeStyles(avatarColor)}>
            <NvIcon icon='path' size='xss-smallest' />
            {t.OFFERINGS.CARD.BADGE.LEARNING_JOURNEY()}
          </div>
        )}
        <div className='course-name'>
          <a
            href={url}
            target='_blank'
            rel='noopener noreferrer'
            data-offering-name={course.catalogId}
          >
            {course.name}
          </a>
        </div>
        {course.type !== OfferingTypes.MENTORSHIP_PROGRAM && (
          <div>
            {course.catalogId}
          </div>
        )}
        <div className='release-and-enrollees'>
          <div>
            {sortDate && moment(sortDate).format('MM/DD/YYYY')}
          </div>
          <div>
            {`${course.numEnrolled ?? 0} ${t.COURSE_ADMIN_DASHBOARD.TABLE.ENROLLEES()}`}
          </div>
        </div>
        <div className='completion'>
          <CompletionRateProgress course={course} />
        </div>
      </div>
    </div>
  );
};

export default CourseRow;
