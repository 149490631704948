/* eslint-disable react/no-danger */
import { useContext } from 'react';
import t from 'react-translate';

import { css } from '@emotion/react';
import { novoEdBlue } from 'athena/styles/colors';

import CollapsiblePanel from 'athena/components/collapsible-panel';
import { config } from '@config/pendo.config.json';
import { PanelStateKey } from 'athena/components/constants';
import MentoringProgramContext from '../../context';

const styles = css`
  a {
    color: ${novoEdBlue} !important;
  }
`;

const ProgramGuidelinesAndExpectations = () => {
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { guideline } = mentoringProgram || {};

  const panelTitle = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.GUIDELINES_AND_EXPECTATIONS.TITLE();

  return (
    guideline && (
      <CollapsiblePanel
        title={panelTitle}
        ariaLabel={panelTitle}
        panelState={{ persistState: true, localStorageKey: PanelStateKey.PROGRAM_GUIDELINES_STATE }}
        dataQa={config.pendo.athena.mentorshipProgram.participantHome.guidelinesAndExpectations}
      >
        <div css={styles} className='mt-4'>
          <div
            className='guideline'
            dangerouslySetInnerHTML={{
              __html: guideline,
            }}
          />
        </div>
      </CollapsiblePanel>
    )
  );
};
export default ProgramGuidelinesAndExpectations;
