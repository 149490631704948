import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { Course, CourseAliases } from 'redux/schemas/models/course';
import { getCourseAliases } from 'redux/selectors/course';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import { black, gray1, gray2, gray5, gray6, white, yellow, primary, success, warning } from 'styles/global_defaults/colors';
import { boldFontWeight, lightFontWeight, openSansCondensed, semiBoldFontWeight, textLargeFontSize, textSmallFontSize, textXSFontSize } from 'styles/global_defaults/fonts';
import { halfSpacing, largeSpacing, quarterSpacing, standardSpacing, threeQuartersSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import CohortManagementBadge from 'cohort_management/components/badge';


export type VariantType = 'journey' | 'admin' | 'mentor';

type BadgeType = {
  variant: VariantType;
  label: string;
  link?: string;
};

const Badge = ({ variant, label, link }: BadgeType) => {
  const styles = css`
    border-radius: 33px;
    font-weight: ${semiBoldFontWeight};
    height: ${standardSpacing}px;
    font-size: ${textSmallFontSize}px;

    padding: 2px ${halfSpacing}px;
    width: fit-content;

    &.journey {
      max-width: 210px;
      padding: 2px ${halfSpacing}px 3px;
      color: ${gray1};
      background-color: ${gray5};
      position: absolute;
      top: 25px;
      left: 125px; // card padding(20) + course image(105 (width(95) + margin(10))
      z-index: 3;
    }

    &.admin {
      color: ${white};
      background-color: ${gray1};
    }

    &.mentor {
      color: ${white};
      background-color: ${success};
    }
  `;

  return (
    <a className={`ellipsis ${variant}`} css={styles} {...(link ? { href: link } : {})}>{label}</a>
  );
};

type CourseItemType = {
  index: number;
  label: string;
  link: string;
  image: string;
  isCurrent: boolean;
  bgcolor: string;
  variant?: VariantType;
  tooltip?: string;
  isClosed?: boolean;
  isAdmin?: boolean;
  badgeLabel?: string;
  badgeUrl?: string;
  isPrimary?: boolean;
  isCohort?: boolean;
  isMentorshipProgram?: boolean;
};

const FlyoutCourseCard = ({ index, label, link, isCurrent, image, bgcolor, variant = 'journey', tooltip, isAdmin = false, isClosed, badgeLabel, badgeUrl, isPrimary, isCohort, isMentorshipProgram }: CourseItemType) => {
  const defaultImage = '/assets/brand/temp.png';
  const aliases: CourseAliases = useSelector((state) => getCourseAliases(state));

  const styles = css`
    order: 2;
    position: relative;

    &.current {
      order: 1;
    }

    .item {
      display: flex;
      height: 100px;
      padding: ${standardSpacing}px;
      position: relative;
      border-bottom: 1px solid ${gray6};
      align-items: center;

      &.closed {
        opacity: 0.5;
      }

      &:hover {
        background-color: ${gray6};

        .course-info {
          .course-label-container {
            .course-label {
              font-weight: ${boldFontWeight};
              color: ${black};
            }
          }
        }

        .current-course-badge {
          color: ${white};
        }
      }

      .course-image {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 95px;
        height: ${tripleSpacing}px;
        margin-right: ${halfSpacing}px;
        ${isMentorshipProgram ? `
          border-radius: ${quarterSpacing}px;
        ` : ''}

        ${image !== defaultImage
          ? `background-image: url('${image}')`
          : `background-color: ${bgcolor};`};
        background-size: cover;
        background-position: center;
        position: relative;

        .current-course-badge {
          background-color: ${primary};
          color: ${white};
          position: absolute;
          left: -${quarterSpacing}px;
          top: -1px;
          box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);
          font-size: ${textXSFontSize}px;
          padding: 2px 3.5px;
          height: 16px;
          font-weight: ${boldFontWeight};
          max-width: 100px;

          ${(isPrimary || isCohort) && css`
            padding-left: ${threeQuartersSpacing}px;
          `};

          &:before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 ${quarterSpacing}px ${quarterSpacing}px 0;
            border-color: transparent ${gray5} transparent transparent;
            bottom: -${quarterSpacing}px;
            left: 0;
          }
        }

        .mentor-plus-diamond {
          display: flex;
          align-items: center;
          justify-content: center;

          height: 25px;
          width: 25px;
          background-color: ${primary};
          transform: rotate(45deg);
          border-top-left-radius: ${quarterSpacing}px;
          border-bottom-right-radius: ${quarterSpacing}px;
          box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.1), 0px 3px 2px rgba(0, 0, 0, 0.1);
        }
      }

      .course-info {
        display: flex;
        flex-direction: column;

        &closed {
          .course-label {
            color: ${gray2};
          }
        }

        .course-label-container {
          display: flex;
          ${variant !== 'journey' && `
            flex-direction: column;
            gap: ${quarterSpacing}px;
          `}

          .course-label {
            font-size: ${textLargeFontSize}px;
            font-weight: ${lightFontWeight};
            font-family: ${openSansCondensed};
            max-width: 210px;
            min-width: 0;

            &.with-journey {
              margin-top: ${largeSpacing}px;
            }
          }
        }

        .course-closed {
          font-size: ${textSmallFontSize}px;
          font-weight: ${semiBoldFontWeight};
          color: ${gray2};
        }
      }
    }
  `;

  return (
    <div className={`${isCurrent ? 'current' : ''}`} css={styles}>
      <a className={`item ${isClosed ? 'closed' : ''}`} data-qa={`flyout-course-card-${index}`} href={link}>
        <div className='course-image'>
          {isCurrent && (
            <div className='current-course-badge ellipsis' data-qa='flyout-course-card-current'>
              {isMentorshipProgram ? t.LHS.COURSE_CARD.CURRENT_PROGRAM() : t.LHS.COURSE_CARD.CURRENT_COURSE({ courseAlias: aliases?.courseAliases?.CourseAlias })}
            </div>
          )}
          {isMentorshipProgram && (
            <div className='mentor-plus-diamond'>
              <NvIcon icon='mentor-plus' size='smallest' className='text-white' />
            </div>
          )}
          <CohortManagementBadge
            course={{
              isPrimary,
              isCohort,
            } as Course}
          />
        </div>

        <div className='course-info'>
          <NvTooltip text={tooltip} placement='top' enabled={isAdmin && !!tooltip}>
            <div className='course-label-container'>
              {(badgeLabel && !badgeUrl) && <Badge variant={variant} label={badgeLabel} />}

              <div className={`course-label ${variant === 'journey' && (!badgeLabel ? 'two-line-ellipsis' : 'ellipsis with-journey')} ${variant !== 'journey' ? 'ellipsis' : ''}`} data-qa={`flyout-course-card-title-${index}`}>
                {label}
              </div>
            </div>
          </NvTooltip>

          {isClosed && (
            <div className='course-closed' data-qa={`flyout-course-card-closed-${index}`}>
              {t.LHS.COURSE_CARD.CLOSED()}
            </div>
          )}
        </div>
      </a>

      {(badgeLabel && badgeUrl) && <Badge variant={variant} label={badgeLabel} link={badgeUrl} />}
    </div>
  );
};

export default FlyoutCourseCard;
