import { css } from '@emotion/react';
import t from 'react-translate';
import NvDropdown, {
  NvDropdownButtonStyle,
  NvDropdownOption,
} from 'shared/components/inputs/nv-dropdown';
import MoreOptionsTarget from 'athena/components/more-options-target';
import { config } from '@config/pendo.config.json';
import { useState } from 'react';
import { commonStyles } from './styles';
import { CellProps } from './types';

const styles = css`
  ${commonStyles};

  .options-target {
    opacity: 0;
    flex-shrink: 0;
  }
`;

const OptionsCell = (props: CellProps) => {
  const { cell, className } = props;
  const {
    row: { original: selectedConnection },
    getContext,
  } = cell;

  const {
    table: {
      options: { meta },
    },
  } = getContext();
  const { handleUnpairConnection } = meta;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dataQATag = config.pendo.athena.mentorshipProgram.connections.connectionMenuDropdown;
  const translationBase = t.MENTORING_PROGRAMS.CONNECTIONS_TABLE;

  const options: NvDropdownOption[] = [
    {
      text: translationBase.ROWS.MENU.UNPAIR_CONNECTION.TITLE(),
      type: 'text',
      callback: () => {
        handleUnpairConnection(selectedConnection);
        setIsDropdownOpen(false);
      },
      dataQa: dataQATag.items.deleteConnectionOption,
    },
  ];

  const handleDropdownToggle = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  return (
    <div className={className} css={styles}>
      <NvDropdown
        items={options}
        buttonStyle={NvDropdownButtonStyle.CUSTOM}
        customTarget={() => <MoreOptionsTarget className='options-target' />}
        altLabel={translationBase.ARIA_LABEL.CONNECTION_OPTIONS_MENU()}
        toggleDataQa={dataQATag.toggle}
        onToggle={handleDropdownToggle}
        ariaExpanded={isDropdownOpen}
        ariaRole='menu'
      />
    </div>
  );
};

export default OptionsCell;
