/* eslint-disable @typescript-eslint/no-shadow */
import t from 'react-translate';
import { useState } from 'react';
import { ImportanceLevel } from 'redux/schemas/models/mentoring-program-matching-rules';
import { css } from '@emotion/react';
import { almostBlack, novoEdBlue } from 'athena/styles/colors';

const commonStyles = css`
  background: ${novoEdBlue};
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
`;

const styles = (gradientLevel: number) => css`
  .slider {
    -webkit-appearance: none;
    appearance: none;
    height: 2px;
    outline: none;
    border-radius: 100px;
    background: linear-gradient(
      to right,
      ${novoEdBlue} ${gradientLevel}%,
      rgba(14, 114, 237, 0.3) ${gradientLevel}%
    );
    transition: background 0.3s;
  }
  .slider::-webkit-slider-thumb {
    ${commonStyles};
  }
  .slider::-moz-range-thumb {
    ${commonStyles};
  }
  .importance-levels {
    color: ${almostBlack};
  }
`;

type ImportanceLevelSliderProps = {
  level: ImportanceLevel;
  onChange: (autoMatchingRule: { importanceLevel: string }) => void;
};

const ImportanceLevelSlider = ({ level, onChange }: ImportanceLevelSliderProps) => {
  const [currentLevel, setCurrentLevel] = useState(level);
  const translationBase = t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.LIST;
  const importanceLevelTranslation = translationBase.IMPORTANCE_LEVEL;
  const importanceLevels = [
    {
      level: ImportanceLevel.NICE_TO_HAVE,
      label: importanceLevelTranslation.NICE_TO_HAVE.TITLE(),
    },
    {
      level: ImportanceLevel.PREFERRED,
      label: importanceLevelTranslation.PREFERRED.TITLE(),
    },
    {
      level: ImportanceLevel.REQUIRED,
      label: importanceLevelTranslation.REQUIRED.TITLE(),
    },
  ];

  const currentIndex = importanceLevels.findIndex(
    item => item.level === currentLevel,
  );
  const maxIndex = importanceLevels.length - 1;
  const gradientLevel = (currentIndex / maxIndex) * 100;

  const handleChangeLevel = ({ target: { value } }) => {
    const newLevel = importanceLevels[value].level;
    setCurrentLevel(newLevel);
    onChange({ importanceLevel: newLevel });
  };

  return (
    <div css={styles(gradientLevel)} className='align-content-center'>
      <input
        className='slider'
        type='range'
        min={0}
        max={maxIndex}
        value={currentIndex}
        onChange={handleChangeLevel}
        aria-label={translationBase.HEADERS.IMPORTANCE_LEVEL()}
        aria-valuetext={currentLevel}
      />
      <div className='d-flex justify-content-between mt-4 text-regular importance-levels'>
        {importanceLevels.map(({ level, label }) => (
          <span key={level}>{label}</span>
        ))}
      </div>
    </div>
  );
};

export default ImportanceLevelSlider;
