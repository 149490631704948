import t from 'react-translate';
import { useSelector } from 'react-redux';
import ClickableContainer from 'components/clickable-container';
import { getMentorshipProgramSession } from 'redux/selectors/mentorship-program-sessions';
import { useContext, useEffect } from 'react';
import { useAppDispatch } from 'redux/store';
import { getSingleMentoringProgramSession } from 'redux/actions/mentoring-program-sessions';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import NvTooltip from 'shared/components/nv-tooltip';
import { MentorshipProgramSession } from 'redux/schemas/models/mentoring-program-sessions';
import { config } from '@config/pendo.config.json';
import NvIcon from 'shared/components/nv-icon';
import { css } from '@emotion/react';
import { blue05, novoEdBlue } from 'athena/styles/colors';

type SessionMenuOptionProps = {
  actionItemId: number;
  sessionId: number;
  showPlanSessionFlyout: (session: MentorshipProgramSession) => void;
};

const styles = css`
  background: ${blue05};
  border-radius: 50%;
  color: ${novoEdBlue};
  padding: 8px;
`;

const SessionMenuOption = ({
  actionItemId,
  sessionId,
  showPlanSessionFlyout,
}: SessionMenuOptionProps) => {
  const dispatch = useAppDispatch();
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: programId } = mentoringProgram || {};
  const session: MentorshipProgramSession = useSelector(
    getMentorshipProgramSession(sessionId),
  );
  const translationBase = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.ACTION_ITEMS.ITEM.TOOLTIP;
  const dataQATag = config.pendo.athena.mentorshipProgram.participantHome.actionItems.item
    .openActionItemSessionFlyout;

  useEffect(() => {
    if (!session) {
      dispatch(getSingleMentoringProgramSession({ programId, sessionId }));
    }
  }, [dispatch, programId, session, sessionId]);

  const ariaLabel = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.ACTION_ITEMS.ARIA_LABEL.SESSION_MENU_OPTION(session?.title);

  return session && showPlanSessionFlyout ? (
    <NvTooltip
      placement='top'
      text={translationBase.ADDED_ON_SESSION(session.title)}
    >
      <ClickableContainer
        css={styles}
        onClick={() => showPlanSessionFlyout(session)}
        data-qa={dataQATag}
        data-qa-id={`${dataQATag}-${actionItemId}`}
        aria-label={ariaLabel}
      >
        <NvIcon icon='calendar' size='xss-smallest' />
      </ClickableContainer>
    </NvTooltip>
  ) : null;
};

export default SessionMenuOption;
