import { css } from '@emotion/react';
import { useContext } from 'react';
import { AngularContext } from 'react-app';
import t from 'react-translate';
import Avatar from 'athena/components/mentoring-program/avatar';
import ClickableContainer from 'components/clickable-container';
import { User } from 'redux/schemas/models/my-account';
import { gray7, gray2 } from 'styles/global_defaults/colors';
import { headerLineHeight, semiBoldFontWeight, textLargeBodyFontSize } from 'styles/global_defaults/fonts';
import { halfSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';
import { config } from '@config/pendo.config.json';

type MenteeCardProps = {
  id?: number;
  user: User;
  programId: number;
};

const MenteeCard = ({
  id,
  user,
  programId,
}: MenteeCardProps) => {
  const styles = css`
    background-color: ${gray7};
    border-radius: ${quarterSpacing}px;
    width: 100%;
    min-width: 520px;
    margin-top: ${halfSpacing}px;

    .name-and-email {
      color: ${gray2};
      font-weight: ${semiBoldFontWeight};
      font-size: ${textLargeBodyFontSize}px;
      line-height: ${headerLineHeight}px;
    }
  `;

  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  // Update this once new route is created
  const goToMentoringProgram = () => $state.go('mentoring-program-single-connection', { programId, connectionId: id });

  const cardTitle = t.USERS.FULL_NAME(user.firstName, user.lastName);

  return (
    <div css={styles}>
      <ClickableContainer
        onClick={goToMentoringProgram}
        data-qa={config.pendo.athena.mentorshipProgram.participantHome.home}
        data-qa-id={`${config.pendo.athena.mentorshipProgram.participantHome.home}_${programId}`}
        aria-label={cardTitle}
      >
        <div className='d-flex py-4 px-5'>
          <Avatar user={user} />
          <div className='d-flex flex-column ml-3'>
            <div className='heading-4'>{cardTitle}</div>
            <div className='name-and-email'>{user.email}{user.email && user.displayLocation && '   •   '}{user.displayLocation}</div>
          </div>
        </div>
      </ClickableContainer>
    </div>
  );
};

export default MenteeCard;
