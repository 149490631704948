import React from 'react';
import * as Yup from 'yup';
import { css } from '@emotion/react';
import { useFormContext, useWatch } from 'react-hook-form';

import t from 'react-translate';
import usePrevious from 'shared/hooks/use-previous';
import AthenaTextInput from 'athena/components/text-input';
import {
  menteeBackgroundColor,
  menteeTextColor,
  mentorBackgroundColor,
  mentorTextColor,
  shadedWhite,
} from 'athena/styles/colors';
import { semiBoldFontWeight } from 'styles/global_defaults/fonts';
import { largeSpacing } from 'styles/global_defaults/scaffolding';
import { MentoringProgramRole } from 'redux/schemas/models/mentoring-program-enrollments';
import NvTooltip from 'shared/components/nv-tooltip';
import { config } from '@config/pendo.config.json';

export const aliasValidationSchema = Yup.string().noNumbers(() => (
  t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.ALIASES.VALIDATION_ERROR()
));

export const PILL_COLOR = {
  [MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR]: {
    text: mentorTextColor,
    background: mentorBackgroundColor,
  },
  [MentoringProgramRole.MENTORSHIP_PROGRAM_MENTEE]: {
    text: menteeTextColor,
    background: menteeBackgroundColor,
  },
};

export const PILL_MAX_WIDTH = 146;

type Props = {
  name: string;
  role: MentoringProgramRole;
};

const AliasSetting = (props: Props) => {
  const { name, role } = props;

  const value = useWatch({ name });
  const previousValue = usePrevious(value);
  const [preview, setPreview] = React.useState(value);
  const [enableTooltip, setEnableTooltip] = React.useState(false);
  const pillRef = React.useRef<HTMLDivElement>(null);
  const lastValueWithoutError = useLastValueWithoutError(name);
  const { setValue } = useFormContext();

  React.useEffect(() => {
    if (previousValue === undefined && value) {
      setPreview(value);
    }
  }, [previousValue, value]);

  React.useEffect(() => {
    if (pillRef?.current) {
      const pillWidth = pillRef.current?.offsetWidth;
      setEnableTooltip(pillWidth >= PILL_MAX_WIDTH);
    }
  }, [preview]);

  const styles = css`
    height: 80px;
    border-top: 1px solid ${shadedWhite};

    &:last-of-type {
      border-bottom: 1px solid ${shadedWhite};
    }

    .name {
      width: 100px;
      font-weight: ${semiBoldFontWeight};
    }

    .alias-field {
      width: 200px;
    }

    .pill {
      height: ${largeSpacing}px;
      font-weight: ${semiBoldFontWeight};
      border-radius: ${largeSpacing / 2}px;
      color: ${PILL_COLOR[role].text};
      background-color: ${PILL_COLOR[role].background};
      max-width: ${PILL_MAX_WIDTH}px;
      div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  `;

  const getRoleName = () => {
    switch (role) {
      case MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR:
        return t.MENTORING_PROGRAMS.ROLES.PARTICIPANTS_CATEGORY.NO_ALIAS_MENTOR();
      case MentoringProgramRole.MENTORSHIP_PROGRAM_MENTEE:
        return t.MENTORING_PROGRAMS.ROLES.PARTICIPANTS_CATEGORY.NO_ALIAS_MENTEE();
      default:
        return undefined;
    }
  };

  const applyPreview = () => {
    if (value) {
      setPreview(lastValueWithoutError);
    } else {
      setValue(name, getRoleName());
      setPreview(getRoleName());
    }
  };

  const handleInputKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      applyPreview();
    }
  };

  return (
    <div className='d-flex align-items-center' css={styles}>
      <div className='name pl-3 pr-3 text-medium gray-2'>
        {getRoleName()}
      </div>
      <AthenaTextInput
        withForm
        name={name}
        showLabel={false}
        onBlur={applyPreview}
        className='alias-field mr-4'
        onKeyDown={handleInputKeyDown}
        dataQa={config.pendo.athena.mentorshipProgram.settings.programSetup.aliasInputField}
        dataQaId={`${config.pendo.athena.mentorshipProgram.settings.programSetup.aliasInputField}_${role}`}
        aria-label={getRoleName()}
      />
      <NvTooltip text={preview} enabled={enableTooltip}>
        <div
          ref={pillRef}
          className='pill d-flex align-items-center pl-2 pr-2 text-small'
        >
          <div>{preview}</div>
        </div>
      </NvTooltip>
    </div>
  );
};

const useLastValueWithoutError = (name: string) => {
  const { watch } = useFormContext();
  const value = watch(name);
  const [lastValueWithoutError, setLastValueWithoutError] = React.useState(value);

  React.useEffect(() => {
    let error = false;
    try {
      aliasValidationSchema.validateSync(value);
    } catch (e) {
      error = true;
    }

    if (!error) {
      setLastValueWithoutError(value);
    }
  }, [value]);

  return lastValueWithoutError;
};

export default AliasSetting;
