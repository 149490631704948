import React from 'react';
import { css, SerializedStyles } from '@emotion/react';

import { gray5 } from 'styles/global_defaults/colors';
import { mobile } from 'styles/global_defaults/media-queries';
import ClickableContainer, {
  ClickableContainerProps,
} from 'components/clickable-container';

export const CARD_WIDTH = 280;
export const MOBILE_CARD_WIDTH = 230;

type RenderProp<T> = (data?: T) => React.ReactNode;

type Props = ClickableContainerProps & {
  renderHeader: RenderProp<boolean>,
  renderContent: RenderProp<boolean>,
  className?: string,
  headerClassName?: string,
  contentClassName?: string,
  style?: React.CSSProperties,
  backdropLayerClassName?: string,
  renderExtraContent?: RenderProp<any>,
  isFocusable?: boolean,
  extraContainerStyles?: SerializedStyles,
};

const BaseCard = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    style,
    className,
    renderHeader,
    renderContent,
    headerClassName,
    contentClassName,
    renderExtraContent,
    backdropLayerClassName,
    extraContainerStyles,
    ...restProps
  } = props;

  const [isMouseIn, setIsMouseIn] = React.useState(false);

  const isResponsive = !style?.width;

  // Separating the styles in two parts to avoid repetition css properties,
  // since the parent of the container should now handle the styles because the
  // extra content (renderExtraContent()) is now out of the ClickableContainer
  // Ticket NOV-92060
  const cardStyles = css`
    height: 280px;
    display: flex;
    flex-direction: column;
    max-width: ${CARD_WIDTH}px;
  `;

  const containerStyles = css`
    ${cardStyles}
    border: 1px solid ${gray5};
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);

    ${isResponsive ? css`
      width: ${CARD_WIDTH}px;

      ${mobile(css`
        width: ${MOBILE_CARD_WIDTH}px;
      `)};
    ` : mobile(css`
      min-width: ${MOBILE_CARD_WIDTH}px;
    `)};

    .card-header {
      height: 100px;
      flex-shrink: 0;
      position: relative;
      box-sizing: content-box;

      .backdrop-layer {
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }

    .card-content {
      flex: 1;
    }

    ${extraContainerStyles};
  `;

  const handleMouseEnter = () => setIsMouseIn(true);
  const handleMouseLeave = () => setIsMouseIn(false);

  return (
    <div
      className={className}
      style={style}
      css={cardStyles}
    >
      <ClickableContainer
        ref={ref}
        css={containerStyles}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...restProps}
      >
        <div
          className={`card-header${headerClassName ? ` ${headerClassName}` : ''}`}
        >
          <div className={`backdrop-layer${backdropLayerClassName ? ` ${backdropLayerClassName}` : ''}`}>
            {renderHeader(isMouseIn)}
          </div>
        </div>
        <div className={`card-content${contentClassName ? ` ${contentClassName}` : ''}`}>
          {renderContent(isMouseIn)}
        </div>
        {renderExtraContent?.()}
      </ClickableContainer>
    </div>
  );
});

export default BaseCard;
