import React, {
  CSSProperties,
  ComponentType,
  Fragment,
  ReactElement,
  ReactNode,
  SVGProps,
  cloneElement,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import { Todo } from 'redux/schemas/models/todos';
import { Course, FeaturedDiscoveryState } from 'redux/schemas/models/course';
import RowListing from 'dashboard/components/row-listing';
import { getCurrentUserTodos } from 'redux/actions/todos';
import { Submission } from 'redux/schemas/models/submissions';
import OfferingCard from 'offerings/components/offering-card';
import JourneyCard from 'offerings/components/journey-card';
import ClickableContainer from 'components/clickable-container';
import { getInstitutionData } from 'redux/actions/institutions';
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';
import { getDiscoveryEnabled } from 'redux/selectors/institutions';
import { getInProgressCoursesAndJourneysFromIds } from 'redux/selectors/learning-journeys';
import { handheld, mobile, screenMdMax, screenSmMax, screenSmMin } from 'styles/global_defaults/media-queries';
import Tab, { Description as TabDescription } from 'dashboard/components/tab';
import LoadingWrapper, { LoaderType } from 'shared/components/loading-wrapper';
import useEntityListByIdList from 'dashboard/hooks/use-entity-list-by-id-list';
import CardsResponsiveListing, { ElementsDictionary } from 'dashboard/components/cards-responsive-listing';
import OfferingCardProgessContextProvider from 'offerings/components/offering-card/offering-card-progress-context';
import useDashboardListingCollapser from 'dashboard/hooks/use-dashboard-listing-collapser';
import NvNoResults from 'shared/components/nv-no-results-panel';
import {
  getCurrentLearning,
  getArchivedOfferings,
  getCompletedOfferings,
  CurrentLearningPermission,
  fetchFeaturedDiscovery,
} from 'redux/actions/courses';
import {
  getCurrentUserSubmissions,
  getCurrentUserActiveDrafts,
} from 'redux/actions/submissions';
import {
  black,
  danger,
  gray4,
  info,
  kelp,
  primary,
  success,
  warning,
  white,
} from 'styles/global_defaults/colors';
import {
  ReactComponent as DashboardTodosIcon,
} from 'styles/icons/dashboard-todos.svg';
import {
  ReactComponent as DashboardDraftsIcon,
} from 'styles/icons/dashboard-drafts.svg';
import {
  doubleSpacing,
  dropdownZIndex,
  extraLargeSpacing,
  halfSpacing,
  largeSpacing,
  quarterSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import {
  ReactComponent as DashboardArchivedIcon,
} from 'styles/icons/dashboard-archived.svg';
import {
  ReactComponent as DashboardCompletedIcon,
} from 'styles/icons/dashboard-completed.svg';
import useDashboardSummary, {
  TabName,
} from 'dashboard/hooks/use-dashboard-summary';
import {
  ReactComponent as DashboardInProgressIcon,
} from 'styles/icons/dashboard-in-progress.svg';
import {
  ReactComponent as DashboardSubmissionsIcon,
} from 'styles/icons/dashboard-submissions.svg';
import { isSafari } from 'recording/services/media-stream';
import { headerRegularLineHeight, normalFontWeight, semiBoldFontWeight, textExtraLargeFontSize, textLargeLineHeight, textMediumFontSize } from 'styles/global_defaults/fonts';
import { Button } from 'react-bootstrap';
import NvIcon from 'shared/components/nv-icon';
import { useMediaQuery } from 'react-responsive';
import { AngularContext } from 'react-app';
import { getFeaturedDiscovery } from 'redux/selectors/course';
import TodoRow from './todos/todo-row';
import NvSubmissionDraftCard from '../../shared/components/submission/nv-submission-draft-card';
import NvSubmissionCard from '../../shared/components/submission/nv-submission-card';
import { config } from '../../../config/pendo.config.json';

type SVGComponentType = ComponentType<SVGProps<SVGElement>>;

const loadingWrapperStyles = css`
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${doubleSpacing}px;
`;

const getTabDescriptionItems = (descriptionInfos: TabDescriptionInfo[]) => descriptionInfos.filter(
  (descriptionInfo) => Boolean(descriptionInfo.count),
).map((descriptionInfo) => ({
  color: descriptionInfo.color,
  text: descriptionInfo.description,
}));

const renderDivider = () => (
  <div className='text-center'>
    <ListingDivider />
  </div>
);

type TabDescriptionInfo = {
  count: number,
  description: string,
  color?: string,
};

type TabData = {
  name: TabName,
  color: string,
  count: number,
  title: string,
  getData?: () => Promise<any[]>,
  iconComponent: SVGComponentType,
  descriptionItems?: TabDescription[],
  renderContent: () => ReactElement,
  setData?: (data: Array<number | string>) => void,
  renderExtraContent?: () => ReactElement,
};

const LearnerDashboard = forwardRef((props, ref) => {
  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const dispatch = useAppDispatch();
  const containerRef = useRef();
  const tabsToRenderRef = useRef<TabData[]>();
  const tabsOrderPreferenceRef = useRef<TabName[]>();
  const discoveryEnabled = useSelector(getDiscoveryEnabled);
  const featuredDiscovery: FeaturedDiscoveryState = useSelector(getFeaturedDiscovery);
  const [currentTab, setCurrentTab] = useState<TabName>();
  const [todosList, setTodosList] = useState<number[]>([]);
  const [draftsList, setDraftsList] = useState<number[]>([]);
  const { currentInstitutionId } = useSelector((state) => state.app);
  const [archivedList, setArchivedList] = useState<string[]>([]);
  const [completedList, setCompletedList] = useState<string[]>([]);
  const [inProgressCoursesList, setInProgressCoursesList] = useState<string[]>([]);
  const [submissionsList, setSubmissionsList] = useState<number[]>([]);

  const [
    isArchivedContentLoading,
    setIsArchivedContentLoading,
  ] = useState(false);

  const [
    isCompletedContentLoading,
    setIsCompletedContentLoading,
  ] = useState(false);

  const [
    isInProgressContentLoading,
    setIsInProgressContentLoading,
  ] = useState(false);

  const isTablet = useMediaQuery({
    query: `(min-width: ${screenSmMin}px) and (max-width: ${screenSmMax}px)`,
  });
  const isDesktop = useMediaQuery({
    query: `(min-width: ${screenMdMax}px)`,
  });

  /**
   * Array that stores the order in which we should determine the default tab.
   */
  const tabsOrderPreference = [
    TabName.IN_PROGRESS,
    TabName.COMPLETED,
    TabName.ARCHIVED,
  ];
  tabsOrderPreferenceRef.current = tabsOrderPreference;

  const {
    loadSummary,
    isSummaryLoading,
    currentSummary: {
      inProgress: {
        closingSoonCount,
        totalCount: inProgressCount,
      },
      discovery: {
        totalCount: discoveryCount,
      },
      activeDrafts: {
        dueSoonCount,
        totalCount: draftsCount,
      },
      todos: {
        totalCount: todosCount,
      },
      submissions: {
        revisionNeededCount,
        pendingApprovalCount,
        recentlyApprovedCount,
        totalCount: submissionsCount,
      },
      completed: {
        withCertificateCount,
        totalCount: completedCount,
      },
      archived: {
        totalCount: archivedCount,
      },
    },
  } = useDashboardSummary();

  const hasUserScrolledDown = useInfiniteScroll(
    containerRef.current,
    standardSpacing,
    false,
  );
  const [courses, journeys] = useSelector(state => getInProgressCoursesAndJourneysFromIds(state, inProgressCoursesList));

  const drafts = useEntityListByIdList<Submission>('submissions', draftsList);
  const todos = useEntityListByIdList<Todo>('todos', todosList);
  const submissions = useEntityListByIdList<Submission>('submissions', submissionsList);
  const completedCourses = useEntityListByIdList<Course>('courses', completedList);

  const renderArchivedTab = !completedCount;
  const hasArchivedLearning = !!archivedList.length;
  const archivedCourses = useEntityListByIdList<Course>('courses', archivedList);

  const getArchivedData = async () => {
    setIsArchivedContentLoading(true);
    const newArchivedList = unwrapResult(await dispatch(getArchivedOfferings()));
    setIsArchivedContentLoading(false);
    return newArchivedList;
  };

  const setArchivedData = (newArchivedList) => setArchivedList(newArchivedList as string[]);

  const renderOfferingCardsResponsiveListing = (
    offerings,
    className = 'cards-listing',
    spaceBetweenCards = undefined,
    offeringCardStyle = {},
    openInNewTab = false,
  ) => (
    <OfferingCardsResponsiveListing
      offerings={offerings}
      className={className}
      collapserClassName='collapser'
      spaceBetweenCards={spaceBetweenCards}
      offeringCardStyle={offeringCardStyle}
      openInNewTab={openInNewTab}
    />
  );

  const tabs: Array<TabData> = [
    {
      color: kelp,
      count: inProgressCount,
      name: TabName.IN_PROGRESS,
      iconComponent: DashboardInProgressIcon,
      title: t.DASHBOARD.TABS.IN_PROGRESS.TITLE(),
      renderContent: () => <InProgressTabContent courses={courses} journeys={journeys} />,
      setData: (newInProgressList) => setInProgressCoursesList(newInProgressList as string[]),
      renderExtraContent: () => {
        const shouldShowDiscoverySection = featuredDiscovery?.loading || !!discoveryCount;

        return discoveryEnabled
          && shouldShowDiscoverySection
          && !isInProgressContentLoading
          && (
            <Fragment>
              {!!inProgressCount && renderDivider()}
              {renderDiscoveryContent()}
            </Fragment>
          );
      },
      descriptionItems: getTabDescriptionItems([{
        color: danger,
        count: closingSoonCount,
        description: t.DASHBOARD.TABS.IN_PROGRESS.X_CLOSING_SOON(closingSoonCount),
      }]),
      getData: async () => {
        const thunkPromiseCourses = dispatch(getCurrentLearning({ permission: CurrentLearningPermission.LEARNER }));
        setIsInProgressContentLoading(true);

        if (discoveryEnabled && !!discoveryCount) {
          dispatch(fetchFeaturedDiscovery());
        }

        const newInProgressList = unwrapResult(await thunkPromiseCourses).response.map(({ catalogId }) => catalogId);
        setIsInProgressContentLoading(false);

        return newInProgressList;
      },
    },
    {
      color: kelp,
      count: draftsCount,
      name: TabName.DRAFTS,
      iconComponent: DashboardDraftsIcon,
      title: t.DASHBOARD.TABS.DRAFTS.TITLE(draftsCount),
      setData: (newDraftsList) => setDraftsList(newDraftsList as number[]),
      getData: async () => unwrapResult(await dispatch(getCurrentUserActiveDrafts())),
      renderContent: () => (
        <CardsResponsiveListing
          items={drafts}
          className='cards-listing'
          collapserClassName='collapser'
          keyExtractor={(draft: Submission) => draft.id}
          renderItem={({ item, props: submissionProps }) => (
            <NvSubmissionDraftCard {...submissionProps} submission={item} />
          )}
        />
      ),
      descriptionItems: getTabDescriptionItems([{
        color: danger,
        count: dueSoonCount,
        description: t.DASHBOARD.TABS.DRAFTS.X_DUE_SOON(dueSoonCount),
      }]),
    },
    {
      color: primary,
      count: todosCount,
      name: TabName.TODOS,
      descriptionItems: [],
      iconComponent: DashboardTodosIcon,
      title: t.DASHBOARD.TABS.TODOS.TITLE(todosCount),
      setData: (newTodos) => setTodosList(newTodos as number[]),
      getData: async () => unwrapResult(await dispatch(getCurrentUserTodos())),
      renderContent: () => (
        <RowListing
          items={todos}
          className='todos-container'
          keyExtractor={(todo) => `${todo.id}-${todo.type}`}
          renderItem={(todo) => <TodoRow todo={todo} />}
        />
      ),
    },
    {
      color: warning,
      count: submissionsCount,
      name: TabName.SUBMISSIONS,
      renderContent: () => (
        <CardsResponsiveListing
          items={submissions}
          className='cards-listing'
          collapserClassName='collapser'
          keyExtractor={(submission: Submission) => submission.id}
          renderItem={({ item, props: submissionProps }) => (
            <NvSubmissionCard {...submissionProps} submission={item} />
          )}
        />
      ),
      iconComponent: DashboardSubmissionsIcon,
      title: t.DASHBOARD.TABS.SUBMISSIONS.TITLE(submissionsCount),
      getData: async () => unwrapResult(await dispatch(getCurrentUserSubmissions())),
      setData: (newSubmissionsList) => setSubmissionsList(newSubmissionsList as number[]),
      descriptionItems: getTabDescriptionItems([
        {
          count: revisionNeededCount,
          description: t.DASHBOARD.TABS.SUBMISSIONS.X_NEED_REVISION(revisionNeededCount),
        },
        {
          count: pendingApprovalCount,
          description: t.DASHBOARD.TABS.SUBMISSIONS.X_PENDING_APPROVAL(pendingApprovalCount),
        },
        {
          count: recentlyApprovedCount,
          description: t.DASHBOARD.TABS.SUBMISSIONS.X_RECENTLY_APPROVED(recentlyApprovedCount),
        },
      ]),
    },
    {
      color: success,
      count: completedCount,
      name: TabName.COMPLETED,
      iconComponent: DashboardCompletedIcon,
      title: t.DASHBOARD.TABS.COMPLETED.TITLE(),
      renderContent: () => (
        <OfferingCardProgessContextProvider progress='completed'>
          {renderOfferingCardsResponsiveListing(completedCourses)}
        </OfferingCardProgessContextProvider>
      ),
      setData: (newCompletedList) => setCompletedList(newCompletedList as string[]),
      getData: async () => {
        const thunkPromise = dispatch(getCompletedOfferings());
        setIsCompletedContentLoading(true);

        getArchivedData().then((newArchivedList) => {
          setArchivedData(newArchivedList);
        });

        const newCompletedList = unwrapResult(await thunkPromise);
        setIsCompletedContentLoading(false);

        return newCompletedList;
      },
      renderExtraContent: () => !isCompletedContentLoading && (
        <LoadingWrapper
          keepRendered={false}
          css={loadingWrapperStyles}
          loaderType={LoaderType.PLACEHOLDER}
          isLoaded={!isArchivedContentLoading}
        >
          {hasArchivedLearning && (
            <ArchiveLearningCollapser>
                {renderOfferingCardsResponsiveListing(archivedCourses)}
            </ArchiveLearningCollapser>
          )}
        </LoadingWrapper>
      ),
      descriptionItems: getTabDescriptionItems([{
        count: withCertificateCount,
        description: t.DASHBOARD.TABS.COMPLETED.X_WITH_CERTIFICATE(withCertificateCount),
      }]),
    },
    {
      color: black,
      descriptionItems: [],
      count: archivedCount,
      name: TabName.ARCHIVED,
      getData: getArchivedData,
      setData: setArchivedData,
      iconComponent: DashboardArchivedIcon,
      title: t.DASHBOARD.TABS.ARCHIVED.TITLE(),
      renderContent: () => renderOfferingCardsResponsiveListing(archivedCourses),
    },
  ];

  const tabsToRender = tabs.filter((tab) => {
    if (discoveryEnabled && tab.name === TabName.IN_PROGRESS) {
      return tab;
    }

    if (tab.name === TabName.ARCHIVED) {
      return renderArchivedTab && tab.count;
    }

    return tab.count;
  });
  tabsToRenderRef.current = tabsToRender;

  const hasMoreThanOneTab = tabsToRender.length > 1;

  function renderDiscoveryContent() {
    const containerStyles = css`
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: ${standardSpacing}px;
      width: 100%;
      max-width: 920px;
      margin: 0 auto;
      padding: ${doubleSpacing}px ${extraLargeSpacing}px;
      background: ${white};
      border-radius: ${halfSpacing}px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20);

      ${handheld(css`
        padding: ${largeSpacing + quarterSpacing}px ${halfSpacing}px;
      `)};

      ${isDesktop && `
        width: fit-content;
        min-width: 920px;
      `}

      .layer-background {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 55%;
        background: ${info};

        ${handheld(css`
          max-height: 285px;
        `)};
      }

      .header {
        text-align: center;
        color: ${black};
        z-index: 1;

        .title {
          font-size: ${textExtraLargeFontSize}px;
          font-weight: ${semiBoldFontWeight};
          line-height: ${headerRegularLineHeight}px;
        }

        .subtitle {
          margin: 0;
          font-size: ${textMediumFontSize + 2}px;
          font-weight: ${normalFontWeight};
          line-height: ${textLargeLineHeight}px;
        }
      }

      .offering-cards {
        display: flex;
        justify-content: center;

        ${handheld(css`
          flex-direction: column;
          align-items: center;
          gap: ${standardSpacing}px;
        `)};
      }

      .button {
        box-shadow: none;
        display: flex;
        align-items: center;
        gap: ${halfSpacing}px;
      }
    `;

    const offeringCardStyle = {
      marginBottom: '0',
    };

    const goToLearningCatalog = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      $state.go('learning-catalog-modal');
      e.currentTarget.blur();
    };

    return (
      <section css={containerStyles}>
        <div className='layer-background' />
        {!!discoveryCount && (
          <div className='header'>
            <span className='title'>
              {t.DASHBOARD.TABS.DISCOVERY.TITLE()}
            </span>
          </div>
        )}
        <LoadingWrapper
          keepRendered={false}
          css={loadingWrapperStyles}
          loaderType={LoaderType.PLACEHOLDER}
          isLoaded={!featuredDiscovery?.loading}
        >
          {renderOfferingCardsResponsiveListing(
            featuredDiscovery?.offerings,
            'offering-cards',
            isTablet ? halfSpacing : standardSpacing,
            offeringCardStyle,
            true,
          )}
        </LoadingWrapper>
        <Button
          onClick={goToLearningCatalog}
          className='button'
          variant='primary'
          aria-label={t.LEARNING_CATALOG.BROWSE_CATALOG()}
          data-qa={config.pendo.learningCatalog.browseCatalog}
        >
          <NvIcon icon='learning-catalog' size='smallest' />
          {t.LEARNING_CATALOG.BROWSE_CATALOG()}
        </Button>
      </section>
    );
  }

  const loadDashboard = useCallback(() => Promise.all([
    loadSummary(),
    // Getting full current institution because currently it's the only way to
    // get the institution profile settings and they're needed for course
    // cards.
    wrapThunkAction(dispatch(
      getInstitutionData({
        institutionId: currentInstitutionId,
      }),
    )),
  ]).then(([summary]) => {
    const defaultTab = tabsOrderPreferenceRef.current.find((tabName) => (discoveryEnabled ? summary[tabName] : summary[tabName].totalCount));

    if (defaultTab) {
      setCurrentTab(defaultTab);
    } else if (tabsToRenderRef.current.length === 1) {
      // Here setting current tab for case where there's only content for one
      // tab that doesn't belong to "tabsOrderPreference" array because we
      // don't show tabs unless they are more than one.
      setCurrentTab(tabsToRenderRef.current[0].name);
    }
  }), [dispatch, loadSummary, currentInstitutionId]);

  useImperativeHandle(ref, () => ({
    reload: loadDashboard,
  }));

  useEffect(() => {
    loadDashboard();
  }, [loadDashboard]);

  const styles = css`
    z-index: 0;
    width: 100%;
    position: relative;
    height: calc(100vh - 145px);
    background: linear-gradient(315.42deg, rgba(220, 253, 253, 0.3) 0%, rgba(247, 251, 232, 0.3) 99.28%), ${white};

    .dashboard-header-container {
      top: 0;
      // to prevent the dropdown menu from overlapping with dashboard header on scroll
      z-index: ${dropdownZIndex + 1};
      position: absolute;
      background: inherit;
      transition: all 0.5s;
      padding: ${largeSpacing}px ${threeQuartersSpacing}px ${doubleSpacing}px;

      ${handheld(css`
        overflow-x: auto;
        justify-content: flex-start;
      `)};

      ${hasUserScrolledDown && css`
        padding: ${threeQuartersSpacing}px;
      `};

      .tab-container {
        margin: 0 ${threeQuartersSpacing}px;
      }
    }

    .dashboard-tab-content {
      height: 100%;
      overflow-y: auto;
      padding: ${hasMoreThanOneTab ? 210 : doubleSpacing}px ${standardSpacing}px 80px;

      .cards-listing {
        ${mobile(css`
          margin-bottom: ${standardSpacing}px;
        `)};
      }

      /* 65 is the approx height of menu bar on safari - NOV-78165 */
      .collapser {
        margin-bottom: ${isSafari ? doubleSpacing + 65 : doubleSpacing}px;
      }

      .todos-container {
        width: 100%;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  `;

  const handleTabClick = (tabName: TabName) => setCurrentTab(tabName);

  const renderIcon = (IconComponent: SVGComponentType) => (
    <div>
      <IconComponent
        width={doubleSpacing}
        height={doubleSpacing}
      />
    </div>
  );

  return (
    <div css={styles}>
      <LoadingWrapper
        keepRendered={false}
        isLoaded={!isSummaryLoading}
        loaderType={LoaderType.PLACEHOLDER}
        css={css`
          ${loadingWrapperStyles};
          padding-top: ${largeSpacing}px;
        `}
      >
        {hasMoreThanOneTab && (
          <div
            className={`dashboard-header-container d-flex w-100 justify-content-md-center${
              hasUserScrolledDown ? ' compact' : ''
            }`}
          >
            {tabsToRender.map((tab) => (
              <ClickableContainer
                key={tab.name}
                className='tab-container'
                onClick={() => handleTabClick(tab.name)}
              >
                <Tab
                  color={tab.color}
                  count={tab.count}
                  title={tab.title}
                  tooltipText={tab.title}
                  isCompact={hasUserScrolledDown}
                  isSelected={currentTab === tab.name}
                  descriptionItems={tab.descriptionItems}
                  renderImage={() => renderIcon(tab.iconComponent)}
                />
              </ClickableContainer>
            ))}
          </div>
        )}
      </LoadingWrapper>
      <div className='dashboard-tab-content' ref={containerRef}>
        {tabsToRender.map((tab) => {
          const isCurrentTabSelected = currentTab === tab.name;

          const loadTabData = () => tab.getData().then((data) => {
            tab.setData(data);
            return data;
          });

          return (
            <Fragment key={tab.name}>
              <ListingContainer
                key={tab.name}
                loadData={loadTabData}
                show={isCurrentTabSelected}
              >
                {tab.renderContent()}
              </ListingContainer>
              {isCurrentTabSelected && tab.renderExtraContent?.()}
            </Fragment>
          );
        })}
        {tabsToRender.length === 0 && featuredDiscovery?.offerings.length === 0 && !isSummaryLoading && (
          <NvNoResults action={() => {}} hideClearSearch noResultsText={t.DASHBOARD.NO_ENROLLMENTS()} />
        )}
      </div>
    </div>
  );
});

const DEFAULT_MAX_ITEMS = 6;

type InProgressTabContentProps = {
  courses: Course[];
  journeys: Course[];
};

const InProgressTabContent = ({ courses, journeys }: InProgressTabContentProps) => {
  const childrenRef = useRef<ElementsDictionary>({});

  const [journeyItemsToRender, collapser] = useDashboardListingCollapser<any>(
    journeys,
    DEFAULT_MAX_ITEMS,
    (triggeredByKeyboard) => {
      if (triggeredByKeyboard) {
        childrenRef.current[DEFAULT_MAX_ITEMS]?.focus();
      }
    },
  );

  const styles = css`
    .journey-cards {
      display: flex;
      flex-direction: column;
      gap: ${doubleSpacing}px;
      max-width: 920px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: ${doubleSpacing}px;
    }
  `;

  return (
    <div className='in-progress-tab-content' css={styles}>
      {journeys.length > 0 && (
        <Fragment>
          <div className='journey-cards'>
            {journeyItemsToRender.map(journey => <JourneyCard key={journey.id} journey={journey} />)}
          </div>
          {collapser && cloneElement(collapser, {
            className: `${collapser.props.className} collapser`,
          })}
          {courses.length > 0 && renderDivider()}
        </Fragment>
      )}
      <div className='course-cards'>
        <OfferingCardsResponsiveListing
          offerings={courses}
          className='cards-listing'
          collapserClassName='collapser'
        />
      </div>
    </div>
  );
};

type ListingContainerProps = {
  show: boolean,
  children: ReactElement,
  loadData: () => Promise<Array<string | number>>,
};

const ListingContainer = (props: ListingContainerProps): ReactElement => {
  const {
    show,
    children,
    loadData,
  } = props;

  const [isLoading, setIsLoading] = useState(show);
  const loadDataRef = useRef<() => Promise<Array<string | number>>>();
  loadDataRef.current = loadData;

  useLayoutEffect(() => {
    if (show) {
      setIsLoading(true);
      loadDataRef.current().then(() => setIsLoading(false));
    }
  }, [show]);

  const renderedContent = (
    <LoadingWrapper
      keepRendered={false}
      isLoaded={!isLoading}
      css={loadingWrapperStyles}
      loaderType={LoaderType.PLACEHOLDER}
    >
      {children}
    </LoadingWrapper>
  );

  return show ? renderedContent : null;
};

type OfferingCardsResponsiveListingProps = {
  offerings: Course[],
  className?: string,
  collapserClassName?: string,
  spaceBetweenCards?: number,
  offeringCardStyle?: CSSProperties,
  openInNewTab?: boolean,
};

const OfferingCardsResponsiveListing = (props: OfferingCardsResponsiveListingProps) => (
  <CardsResponsiveListing
    items={props.offerings}
    className={props.className}
    collapserClassName={props.collapserClassName}
    keyExtractor={(course: Course) => course.catalogId}
    spaceBetweenCards={props.spaceBetweenCards}
    renderItem={({ item, props: cardProps }) => (
      <OfferingCard
        offering={item}
        ref={cardProps.ref}
        style={{ ...cardProps.style, ...props.offeringCardStyle }}
        openInNewTab={props.openInNewTab}
      />
    )}
  />
);

const ListingDivider = () => {
  const styles = css`
    height: 1px;
    width: 300px;
    display: inline-block;
    background-color: ${gray4};
    margin-bottom: ${doubleSpacing}px;
  `;

  return <div css={styles} />;
};

type ArchiveLearningCollapserProps = {
  children: ReactNode,
};

const ArchiveLearningCollapser = (props: ArchiveLearningCollapserProps) => {
  const { children } = props;
  const [show, setShow] = useState(false);

  const styles = css`
    .archived-learning-toggle, .archived-learning-title {
      margin-bottom: ${doubleSpacing}px;
    }
  `;

  const handleToggleClick = () => setShow((previousShow) => !previousShow);

  return (
    <div css={styles}>
      <div className='text-center'>
        <button
          type='button'
          onClick={handleToggleClick}
          className='btn btn-link hovered archived-learning-toggle'
        >
          {show
            ? t.DASHBOARD.TABS.COMPLETED.HIDE_ARCHIVED_LEARNING()
            : t.DASHBOARD.TABS.COMPLETED.VIEW_ARCHIVED_LEARNING()}
        </button>
      </div>
      {show && (
        <Fragment>
          {renderDivider()}
          <div className='text-center course-subtitle archived-learning-title'>
            {t.DASHBOARD.TABS.COMPLETED.ARCHIVED_CONTENT_TITLE()}
          </div>
          {children}
        </Fragment>
      )}
    </div>
  );
};

export default LearnerDashboard;
