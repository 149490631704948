/* eslint-disable import/prefer-default-export */
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Result } from 'redux/schemas/api';
import { FetchCourseParams, FetchTeachingTeamMembersParams, GetActiveStudentsForCourseParams } from 'redux/schemas/api/course';
import { AutomaticCompletionCriteria, FullCourse, LiveSessionSettings } from 'redux/schemas/models/courseFull';
import { BasicEnrollment, Course, CourseUsedFor } from 'redux/schemas/models/course';
import t from 'react-translate';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { CourseAdminDashboardCounts } from 'redux/schemas/models/course-admin';
import { CombinedCourse } from 'redux/schemas';
import { CourseCompletionStatusState } from 'redux/schemas/app/course-completion-status';
import { InfiniteLoadingParams } from 'redux/schemas/api/shared';
import { addAlertMessage } from './alert-messages';
import { makeQueryParamString } from './helpers';

/**
 * load existing course into redux from angularjs side
 */
export const loadCourse = createAction<FullCourse>('LOAD_COURSE');

export const setCurrentCatalogId = createAction<{
  id: number,
  catalogId: string,
}>('SET_CATALOG_ID');

export const unsetCurrentCatalogId = createAction('UNSET_CATALOG_ID');

async function fetchCourse(params: FetchCourseParams) {
  const response = await axios.get<Result<FullCourse>>(`${params.catalogId}.json`);
  return response.data.result;
}

export const getCourse = createAsyncThunk(
  'FETCH_COURSE',
  fetchCourse,
);

async function fetchTeachingTeamMembers(params: FetchTeachingTeamMembersParams) {
  const response = await axios.get(`/${params.catalogId}/teaching_team_list.json`);
  return response.data.result;
}

export const getTeachingTeamMembers = createAsyncThunk(
  'GET_COURSE_TEACHING_TEAM_MEMBERS',
  fetchTeachingTeamMembers,
);

export const getActiveStudentsForCourse = createAsyncThunk(
  'GET_COURSE_ACTIVE_STUDENTS',
  async (params: GetActiveStudentsForCourseParams) => {
    const enrolledStudentsPathResponse = await axios.get(`/${params.catalogId}/enrolled_students.json`);
    const response = await axios.get(enrolledStudentsPathResponse.data.result);
    return response.data;
  },
);

export const patchCourse = createAsyncThunk('PATCH_COURSE', async (params: any) => {
  const response = await axios.put(`/courses/${params.id}.json`, {
    course: params,
  });

  return response.data.result;
});

export const deleteCourse = createAsyncThunk(
  'DELETE_COURSE',
  async ({ courseId }: { courseId: number, courseCatalogId: string }, { rejectWithValue }) => {
    try {
      const response = await axios.delete<Course>(`/courses/${courseId}`).then(deleteResponse => deleteResponse.data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const setPerCourseLicense = createAsyncThunk('SET_PER_COURSE_LICENSE', async (params: any, { dispatch, rejectWithValue }) => {
  try {
    const response = await axios.put(`/${params.catalogId}/update_licensed_by_instance.json`, {
      licensedByInstance: params.licensedByInstance,
    });
    return response.data.result;
  } catch (error) {
    dispatch(addAlertMessage({
      type: AlertMessageType.ERROR,
      header: t.FORM.ERROR(),
    }));
    return rejectWithValue(error);
  }
});

export const setCourses = createAction<Course[]>('SET_COURSES');

export const getFlyoutCurrentLearning = createAsyncThunk(
  'GET_FLYOUT_LEARNING',
  fetchFlyoutCurrentLearning,
);

async function fetchFlyoutCurrentLearning(params: InfiniteLoadingParams) {
  const response = await axios.get('/users/current_learning.json?all=1', { params: { ...params, skip_mentoring: 1 } });
  return response.data.result;
}

export const getFlyoutMyMentees = createAsyncThunk(
  'GET_FLYOUT_MY_MENTEES',
  fetchFlyoutMyMentees,
);

async function fetchFlyoutMyMentees(params: InfiniteLoadingParams) {
  const response = await axios.get('/users/mentees_info.json', { params });
  return response.data.result;
}

export const getFlyoutMemberships = createAsyncThunk(
  'GET_FLYOUT_MEMBERSHIPS',
  fetchFlyoutMemberships,
);

async function fetchFlyoutMemberships(params: InfiniteLoadingParams) {
  const response = await axios.get('/users/current_memberships.json', { params });
  return response.data.result;
}

export const getFlyoutMembershipsCurrentCourse = createAsyncThunk(
  'GET_FLYOUT_MEMBERSHIPS_CURRENT_COURSE',
  fetchFlyoutMembershipsCurrentCourse,
);
async function fetchFlyoutMembershipsCurrentCourse(params: { course_id }) {
  const response = await axios.get('/users/current_memberships.json', { params });
  return response.data.result;
}

export const addCurrentCourseMyLearning = createAction<BasicEnrollment>('ADD_CURRENT_COURSE_MY_LEARNING');

export const setCourseAdminDashboardCounts = createAction<CourseAdminDashboardCounts>('SET_COURSE_ADMIN_DASHBOARD_COUNTS');

export enum CurrentLearningPermission {
  COURSE_ADMIN = 'course_admin',
  LEARNER = 'learner',
  INSTITUTION = 'institution',
}

type CurrentLearningParams = {
  permission?: CurrentLearningPermission;
  count_only?: number;
};

const defaultParams = {
  permission: CurrentLearningPermission.LEARNER,
};

export const getCurrentLearning = createAsyncThunk(
  'GET_CURRENT_LEARNING',
  async (params: CurrentLearningParams = defaultParams, thunkAPI) => {
    const response = (await axios.get('/users/current_learning.json', { params })).data.result;

    if (Array.isArray(response)) {
      const updatedResponse = response.map((offering) => ({
        /**
         * This is a little hacky. We index the offerings by catalogId,
         * so we need to add something for programs.
         * Adding the `$` character ensures that no course will ever have that
         * catalogId (courses' catalogIds cannot contain special characters).
         * TODO: Update the way we index. Also, update the completed_courses API below
         */
        catalogId: `$-mentorship-program-${offering.id}`,
        ...offering,
      }));

      thunkAPI.dispatch(setCourses(updatedResponse));

      return {
        response: updatedResponse,
      };
    }
    if (typeof response === 'object' && response !== null && response?.active >= 0) {
      thunkAPI.dispatch(setCourseAdminDashboardCounts(response as CourseAdminDashboardCounts));
    }

    return {
      response,
    };
  },
);

/**
 * Given that the "/users/completed_courses.json" request is reused for
 * completed and archived offerings by design (defined by backend engineer) this
 * function exists to apply the right request query param according to whether
 * we want to fetch only the incompleted offerings or the completed ones.
 */
const loadCompletedCourses = async (loadArchived: boolean, thunkAPI) => {
  const params = {};

  params[loadArchived ? 'incomplete_only' : 'completed_only'] = true;

  const courses = (await axios.get('/users/completed_courses.json', {
    params,
  })).data.result;

  const updatedCourses = courses.map((offering) => ({
    // See the comment in the current_learning API above
    catalogId: `$-mentorship-program-${offering.id}`,
    ...offering,
  }));

  thunkAPI.dispatch(setCourses(updatedCourses));

  return updatedCourses.map((course) => course.catalogId);
};

export const getCompletedOfferings = createAsyncThunk(
  'GET_COMPLETED_OFFERINGS',
  (arg, thunkAPI) => loadCompletedCourses(false, thunkAPI),
);

export const getArchivedOfferings = createAsyncThunk(
  'GET_ARCHIVED_OFFERINGS',
  (arg, thunkAPI) => loadCompletedCourses(true, thunkAPI),
);

export const fetchFeaturedDiscovery = createAsyncThunk(
  'GET_FEATURED_DISCOVERY',
  async () => {
    const response = await axios.get('/courses/discovery.json', {
      params: {
        page: 1,
        page_size: 3,
      },
    });
    return response.data.result;
  },
);

export const fetchDiscovery = createAsyncThunk(
  'GET_DISCOVERY',
  async (params: InfiniteLoadingParams) => {
    const filters = Object.entries(params.filters).map(([key, values]) => `${key}=${values.join(',')}`).join('&');

    const response = await axios.get(`/courses/discovery.json?${filters}`, {
      params: {
        page: params.page,
        page_size: params.page_size,
        query: params.query,
      },
    });
    return response.data.result;
  },
);

export const unsetDiscoveryParams = createAction('UNSET_DISCOVERY_PARAMS');

export const auditCourse = createAsyncThunk(
  'AUDIT_COURSE',
  (catalogId: string, thunkAPI) => axios.post(`/${catalogId}/audit.json`).catch((e) => {
    thunkAPI.dispatch(addAlertMessage({
      header: t.FORM.ERROR(),
      type: AlertMessageType.ERROR,
      message: t.FORM.ERROR_SOMETHING_WRONG(),
    }));

    return e;
  }),
);

export const reenrollCourse = createAsyncThunk(
  'REENROLL_COURSE',
  async (catalogId: string, thunkAPI) => {
    try {
      const response = await axios.post(`/${catalogId}/reenroll.json`);

      return response.data.result;
    } catch (e) {
      thunkAPI.dispatch(addAlertMessage({
        header: t.FORM.ERROR(),
        type: AlertMessageType.ERROR,
        message: t.FORM.ERROR_SOMETHING_WRONG(),
      }));

      return e;
    }
  },
);

export const withdrawCourse = createAsyncThunk(
  'WITHDRAW_COURSE',
  (catalogId: string, thunkAPI) => axios.post(`/${catalogId}/unenroll.json`).catch((e) => {
    thunkAPI.dispatch(addAlertMessage({
      header: t.FORM.ERROR(),
      type: AlertMessageType.ERROR,
      message: t.FORM.ERROR_SOMETHING_WRONG(),
    }));

    return e;
  }),
);

export const updateCourseFromAngular = createAction<Partial<CombinedCourse>>(
  'UPDATE_COURSE_FROM_ANGULAR',
);

export const updateCoursePrimaryStatus = createAsyncThunk(
  'UPDATE_COURSE_PRIMARY_STATUS',
  async ({ catalogId, usedFor }: { catalogId: string, usedFor: CourseUsedFor }) => {
    const response = await axios.put(`/courses/${catalogId}/manage_primary_course`, {
      usedFor,
    });

    return response.data.result;
  },
);

export const removeCohortStatus = createAsyncThunk(
  'REMOVE_COHORT_STATUS',
  async (catalogId: string) => {
    const response = await axios.put(`/courses/${catalogId}/unlink_from_primary`);

    return response.data.result;
  },
);

export const setShowCourseCompletionStatusModal = createAction<boolean>('SET_COURSE_COMPLETION_STATUS_MODAL');

export const fetchCourseCompletionStatus = createAsyncThunk<Partial<CourseCompletionStatusState>, { catalogId: string, journeyId: string }>(
  'SET_COURSE_COMPLETION_STATUS',
  async ({ catalogId, journeyId }) => {
    const response = await axios.get(`/courses/${catalogId}/completion_status.json?journey=${journeyId}`);

    return response.data.result;
  },
);

export const getCourseCompletionCriteria = createAsyncThunk<AutomaticCompletionCriteria, { catalogId: string }>(
  'GET_COURSE_COMPLETION_CRITERIA',
  (params: { catalogId: string }) => axios.get(
    `${params.catalogId}/course_completion_criteria.json`,
  ).then((response) => response.data.result),
);

export const getUserCourse = createAsyncThunk(
  'GET_USER_COURSE',
  async (params: { courseId: number }) => {
    const query = makeQueryParamString(params);
    const response = await axios.get(`/user_course_info.json?${query}`);
    return response.data.result;
  },
);

export const getLiveSessionSettings = createAsyncThunk<LiveSessionSettings, { catalogId: string, userId?: number }>(
  'GET_LIVE_SESSION_SETTINGS',
  async (params: { catalogId: string, userId: number }) => {
    const query = makeQueryParamString({ userId: params.userId });
    const response = await axios.get(`${params.catalogId}/user_courses/live_session_settings.json?${query}`);
    return response.data.result;
  },
);

async function archiveUnarchiveCourse(params: { catalogId: string, courseId: number, archive: boolean }, thunkAPI) {
  try {
    const response = await axios.put(`/courses/${params.courseId}/toggle_archive`, {
      archive: params.archive,
    });
    return response.data.result;
  } catch (e) {
    thunkAPI.dispatch(addAlertMessage({
      header: t.FORM.ERROR(),
      type: AlertMessageType.ERROR,
      message: t.FORM.ERROR_SOMETHING_WRONG(),
    }));

    return null;
  }
}

export const toggleArchiveCourse = createAsyncThunk(
  'TOGGLE_ARCHIVE_COURSE',
  archiveUnarchiveCourse,
);
