/* @ngInject */
export default function DashboardHeaderController(
  CurrentPermissionsManager,
  CurrentUserManager,
  InstitutionsManager,
  RolesService,
  $scope,
) {
  const hasLearnerDashboard = CurrentUserManager.getProgramsAsMenteeCount() || CurrentUserManager.user.enrollments.some(enrollment => RolesService.isLearner(enrollment.roles));
  const hasSupervisorDashboard = CurrentUserManager.getProgramsAsMentorCount() || CurrentPermissionsManager.isSupervisor();
  const hasAdminDashboard = CurrentUserManager.getProgramsAsAdminCount() || CurrentUserManager.isCourseAdminInAtLeastOneCourse();
  const hasOrgMentorDashboard = CurrentPermissionsManager.hasOrgMentorPermissions();

  const vm = {
    titleBackgroundColor: 'linear-gradient(315.42deg, rgba(220, 253, 253, 0.3) 0%, rgba(247, 251, 232, 0.3) 99.28%), #FFFFFF',
    CurrentPermissionsManager,
    InstitutionsManager,
    tabs: [],
  };

  if (hasAdminDashboard) {
    vm.tabs.push({
      name: 'COURSE_ADMIN_DASHBOARD.ADMIN_DASHBOARD',
      state: 'course-admin-dashboard',
    });
  }
  if (hasLearnerDashboard) {
    vm.tabs.push({
      name: 'DASHBOARD.TITLE',
      state: 'dashboard',
    });
  }
  if (hasSupervisorDashboard) {
    vm.tabs.push({
      name: 'SUPERVISOR_ADMIN_DASHBOARD.TITLE',
      state: 'course-supervisor-dashboard',
    });
  }
  if (hasOrgMentorDashboard) {
    vm.tabs.push({
      name: 'MENTOR_DASHBOARD.TITLE',
      state: 'mentor-dashboard',
      translateValues: {
        MentorTitleAlias: InstitutionsManager.institution.roleAliases.mentor.singularizedTitleized,
      },
    });
  }

  if (vm.tabs.length === 1 && (vm.tabs[0].state === 'dashboard' || vm.tabs[0].state === 'course-supervisor-dashboard' || vm.tabs[0].state === 'mentor-dashboard')) {
    // Change the name to 'My Dashboard' if there's only one tab and it's not the Admin Dashboard
    vm.tabs[0].name = 'LHS.ACCOUNT_MENU.MY_DASHBOARD';
  }
  if (vm.tabs.length === 0 && InstitutionsManager.institution.discoveryEnabled) {
    vm.tabs.push({
      name: 'LHS.ACCOUNT_MENU.MY_DASHBOARD',
      state: 'dashboard',
    });
  }

  $scope.$watch(
    () => vm.InstitutionsManager.institution.roleAliases.mentor.singularizedTitleized,
    (newValue, oldValue) => {
      if (vm.CurrentPermissionsManager.hasOrgMentorPermissions()) {
        vm.tabs[vm.tabs.length - 1].translateValues.MentorTitleAlias = newValue;
      }
    },
  );

  return vm;
}
