/* eslint-disable react-hooks/exhaustive-deps */
import t from 'react-translate';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import NvTooltip from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import moment from 'moment';
import { useMemo } from 'react';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { success } from 'styles/global_defaults/colors';
import { headerLineHeight } from 'styles/global_defaults/fonts';
import { config } from '@config/pendo.config.json';

const styles = css`
  .not-set-label {
    line-height: ${headerLineHeight}px;
  }
  .set-label {
    gap: ${standardSpacing}px;
  }
  .announcement-check {
    color: ${success};
  }
`;

const getLink = (announcementDate, translationBase) => (announcementDate ? null : (
  <Link
    to='/settings?tab=matching'
    className='link bold text-nowrap text-large-body'
    data-qa={config.pendo.athena.mentorshipProgram.autoMatchingModal.setAnnouncementDateLink}
    aria-label={t.MENTORING_PROGRAMS.PROGRAM_HEADER_MENU.AUTO_MATCHING.MODAL.MATCH_ANNOUNCEMENT_DATE.ARIA_LABEL.SET_DATE()}
  >
    {translationBase.SET_DATE()}
  </Link>
));

const hasMatchDatePassed = (matchDate) => moment(matchDate).isBefore(moment());

const getNotification = (announcementDate, translationBase) => (
  announcementDate ? (
    <div className='d-flex set-label col text-medium align-items-center'>
      <NvIcon icon='check' size='smallest' className='announcement-check' />
      <span className='mr-1'>{translationBase.ANNOUNCED_ON(moment(announcementDate).format('MMM D, YYYY [at] h:mm A'), hasMatchDatePassed(announcementDate))}</span>
      <NvTooltip
        text={translationBase.TOOLTIP()}
        placement='top'
        enabled
      >
        <NvIcon icon='info' size='smallest' className='link' />
      </NvTooltip>
    </div>
  ) : (
    <span className='not-set-label text-large-body gray-1 italic mr-5'>{translationBase.NOT_SET()}</span>
  )
);

const AnnouncementDateNotification = ({ announcementDate }) => {
  const translationBase = t.MENTORING_PROGRAMS.PROGRAM_HEADER_MENU.AUTO_MATCHING.MODAL.MATCH_ANNOUNCEMENT_DATE;

  const notification = useMemo(() => [
    getNotification(announcementDate, translationBase),
    getLink(announcementDate, translationBase),
  ], [announcementDate]);

  return (
    <div css={styles} className='d-flex justify-content-between align-items-center wrapper'>
      {notification}
    </div>
  );
};

export default AnnouncementDateNotification;
