import React, { useRef } from 'react';
import * as Yup from 'yup';
import pick from 'lodash/pick';
import { css } from '@emotion/core';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import t from 'react-translate';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { getMentoringProgram } from 'redux/selectors/mentoring-programs';
import { DESCRIPTION_MAX_LENGTH, NAME_MAX_LENGTH } from 'athena/components/mentoring-program/constants';
import { config } from '@config/pendo.config.json';
import AthenaTextInput from './text-input';
import AthenaTextarea from './textarea';

export type FormValue = {
  name: string;
  description: string;
};

type Props = {
  programId?: number;
  show: boolean;
  onClose: () => void;
  onSubmit: (formValues: FormValue) => void;
};

const ProgramBasicsModal = (props: Props) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t.VALIDATION.REQUIRED()).max(NAME_MAX_LENGTH, t.VALIDATION.MAX_LENGTH(`${NAME_MAX_LENGTH}`)),
    description: Yup.string().max(DESCRIPTION_MAX_LENGTH, t.VALIDATION.MAX_LENGTH(`${DESCRIPTION_MAX_LENGTH}`)),
  });
  const { show, onClose, programId, onSubmit } = props;
  const nameRef = useRef<HTMLInputElement>(null);

  const isEditing = !!programId;

  const editingProgram = useSelector((state) => getMentoringProgram(state, programId));

  const rhfMethods = useForm<FormValue>({
    mode: 'all',
    shouldUnregister: true,
    defaultValues: isEditing ? pick(editingProgram, ['name', 'description']) : {
      name: '',
      description: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const { formState, handleSubmit, reset: resetForm } = rhfMethods;


  React.useEffect(() => {
    if (show) {
      nameRef.current?.focus();
    } else {
      resetForm(isEditing ? pick(editingProgram, ['name', 'description']) : {
        name: '',
        description: '',
      });
    }
  }, [show, editingProgram, resetForm, isEditing]);

  const styles = css`
    .fields-container {
      flex: 1;

      .program-description {
        min-height: 100px;
      }
    }
  `;

  const headerLabel = isEditing ? t.MENTORING_PROGRAMS.EDIT_PROGRAM_BASICS() : t.MENTORING_PROGRAMS.CREATE_NEW_PROGRAM();
  const buttonLabel = isEditing ? t.FORM.UPDATE() : t.FORM.CREATE();

  return (
    <NvModal
      width={600}
      show={show}
      height={420}
      onClose={onClose}
      type={ModalType.FIXED}
      header={headerLabel}
      body={(
        <FormProvider {...rhfMethods}>
          <form onSubmit={handleSubmit(onSubmit)} css={styles} className='p-4 h-100 d-flex flex-column'>
            <div className='fields-container'>
              <AthenaTextInput
                ref={nameRef}
                withForm
                required
                name='name'
                className='mb-4'
                label={t.MENTORING_PROGRAMS.PROGRAM_NAME_LABEL()}
                placeholder={t.MENTORING_PROGRAMS.PROGRAM_NAME_PLACEHOLDER()}
                validationErrorTitle={t.FORM.WARNING()}
              />
              <AthenaTextarea
                withForm
                name='description'
                className='program-description'
                label={t.MENTORING_PROGRAMS.DESCRIPTION_LABEL()}
                placeholder={t.MENTORING_PROGRAMS.DESCRIPTION_PLACEHOLDER()}
              />
            </div>
            <div className='d-flex justify-content-center'>
              <Button
                type='submit'
                aria-label={headerLabel}
                disabled={!formState.isValid || !formState.isDirty}
                data-qa={isEditing ? config.pendo.athena.mentorshipProgram.programUpdated : config.pendo.athena.mentorshipProgram.programCreated}
                data-qa-id={isEditing ? `${config.pendo.athena.mentorshipProgram.programUpdated}_${programId}` : ''}
              >
                {buttonLabel}
              </Button>
            </div>
          </form>
        </FormProvider>
        )}
    />
  );
};

export default ProgramBasicsModal;
