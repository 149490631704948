import t from 'react-translate';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { css } from '@emotion/react';
import { config } from '@config/pendo.config.json';
import CollapsibleRowMenu, {
  CollapsibleRowMenuButton,
} from 'components/collapsible-row-menu';

const styles = css`
  position: absolute;
  right: 0;
  top: ${standardSpacing}px;
`;

type OptionsMenuProps = {
  onDelete: () => void;
};

const OptionsMenu = ({ onDelete }: OptionsMenuProps) => {
  const translationBase = t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.ARIA_LABEL;

  return (
    <CollapsibleRowMenu css={styles} ariaLabel={translationBase.MATCHING_RULE_OPTIONS_MENU()}>
      <>
        <CollapsibleRowMenuButton
          icon='trash'
          onClick={onDelete}
          data-qa={
          config.pendo.athena.mentorshipProgram.settings.matchingRules
            .deleteMatchingRule
        }
          aria-label={translationBase.DELETE_MATCHING_RULE()}
        />
      </>
    </CollapsibleRowMenu>
  );
};

export default OptionsMenu;
