import axios from 'axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { AutoMatchingStatus, ProgramCounts, SaveStatus } from 'redux/schemas/app/mentoring-programs';
import {
  AutoMatchingType,
  MentoringProgram,
  MentoringProgramNormalized,
} from 'redux/schemas/models/mentoring-programs';
import { makeQueryParams, makeQueryParamString } from './helpers';

type CreateMentoringProgramParams = {
  name: string;
  description: string;
};

// eslint-disable-next-line import/prefer-default-export
export const createMentoringProgram = createAsyncThunk<MentoringProgram, CreateMentoringProgramParams>(
  'CREATE_MENTORING_PROGRAM',
  async (params) => {
    const response = await axios.post('/mentorship_programs.json', {
      mentorshipProgram: params,
    });

    return response.data.result.mentorshipProgram;
  },
);

type AutoMatchingParams = {
  autoMatchingEnabled: boolean;
  autoMatchingType: AutoMatchingType;
};

type UpdateMentoringProgramParams = Partial<CreateMentoringProgramParams> & Partial<AutoMatchingParams> & {
  id: number;
  maxMentees?: number;
  mentorAlias?: string;
  menteeAlias?: string;
  guideline?: string;
  releasedDate?: string;
  endDate?: string;
  matchAnnouncementDate?: string;
  headerColor?: string;
  headerBackground?: any;
};

const getMatchAnnouncementDate = (matchAnnouncementDate: string) => {
  if (matchAnnouncementDate === '') return '';
  return matchAnnouncementDate
    ? new Date(matchAnnouncementDate).toISOString()
    : undefined;
};

export const updateMentoringProgram = createAsyncThunk<MentoringProgram, UpdateMentoringProgramParams>(
  'UPDATE_MENTORING_PROGRAM',
  async ({
    id,
    ...restParams
  }) => {
    const params = {
      name: restParams.name || undefined,
      description: restParams.description || '',
      auto_matching_enabled: restParams.autoMatchingEnabled || false,
      auto_matching_type: restParams.autoMatchingType || undefined,
      program_alias: (restParams.mentorAlias || restParams.menteeAlias) ? {
        mentor: restParams.mentorAlias,
        mentee: restParams.menteeAlias,
      } : undefined,
      concurrent_mentor_connection: restParams.maxMentees,
      header_color: restParams.headerColor,
      header_background: restParams.headerBackground,
      guideline: restParams.guideline,
      released_date: restParams.releasedDate ? new Date(restParams.releasedDate).toISOString() : undefined,
      end_date: restParams.endDate ? new Date(restParams.endDate).toISOString() : undefined,
      match_announcement_date: getMatchAnnouncementDate(restParams.matchAnnouncementDate),
    };

    const response = await axios.put(`/mentorship_programs/${id}.json`, {
      mentorshipProgram: params,
    });

    return response.data.result.mentorshipProgram;
  },
);

export const updateMentoringProgramHeader = createAsyncThunk<MentoringProgram, UpdateMentoringProgramParams>(
  'UPDATE_MENTORING_PROGRAM_HEADER',
  async ({ id, headerColor, headerBackground }) => {
    const formData = new FormData();

    formData.append('mentorship_program[header_color]', headerColor);
    formData.append('mentorship_program[header_background]', headerBackground);

    const response = await axios.put(
      `/mentorship_programs/${id}.json`,
      formData,
    );

    return response.data.result.mentorshipProgram;
  },
);

export const deleteMentoringProgram = createAsyncThunk<boolean, number>(
  'DELETE_MENTORING_PROGRAM',
  async (id) => {
    const response = await axios.delete(`/mentorship_programs/${id}.json`);

    return response.data.result;
  },
);

export type GetMentoringProgramParams = {
  page: number;
  pageSize: number;
  sorting?: string[];
};

export const getMentoringPrograms = createAsyncThunk<{ response: MentoringProgramNormalized[] }, GetMentoringProgramParams>(
  'GET_MENTORING_PROGRAMS',
  async (params) => {
    const queryParams = makeQueryParams(params, params.pageSize, params.page);
    const paramString = makeQueryParamString(queryParams);

    const response = await axios.get(`/mentorship_programs?${paramString}`);
    return {
      response: response.data.result,
    };
  },
);

export const getMentoringProgram = createAsyncThunk<MentoringProgram, number>(
  'GET_MENTORING_PROGRAM',
  async (programId) => {
    try {
      const response = await axios.get(`mentorship_programs/${programId}.json`);
      return response.data.result;
    } catch (error) {
      const errorMessage = error.response?.data?.error?.code || 'An unknown error occurred';
      const mentorshipProgramName = error.response.data.error.error_code_params.mentorship_program_name ?? '';
      const responseError = JSON.stringify({ message: errorMessage, mentorshipProgramName });
      return Promise.reject(new Error(responseError));
    }
  },
);

type FetchProgramCountsParams = {
  programId: number;
  setLoading: boolean;
};

export const fetchProgramCounts = createAsyncThunk<ProgramCounts, FetchProgramCountsParams>(
  'FETCH_PROGRAM_COUNTS',
  async ({ programId }) => {
    const response = await axios.get(`/mentorship_programs/${programId}/count.json`);

    return response.data.result;
  },
);

type FirstConnectionAnnouncedParams = {
  programId: number;
  programEnrollmentId: number;
};

export const notifyFirstConnectionAnnounced = createAsyncThunk<boolean, FirstConnectionAnnouncedParams>(
  'FIRST_CONNECTION_ANNOUNCED',
  async ({ programId, programEnrollmentId }) => {
    const response = await axios.post(`/mentorship_programs/${programId}/mentorship_program_enrollment_management/${programEnrollmentId}/first_connection_announced.json`);

    return response.data.result;
  },
);

export const setAutoMatchingStatus = createAction<{
  autoMatchingStatus: AutoMatchingStatus;
}>('SET_AUTOMATCHING_STATUS');

export const setAutoMatchingLastUpdated = createAction<{
  mentorshipProgramId: number;
  autoMatchingLastUpdated: Date;
}>('SET_AUTOMATCHING_LAST_UPDATED');

export const setConnectionsCount = createAction<{
  totalConnectionsCount: number;
}>('SET_CONNECTIONS_COUNT');

export const setSaveStatus = createAction<{
  newStatus: SaveStatus;
}>('SET_SAVE_STATUS');

