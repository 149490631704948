import React from 'react';
import _ from 'underscore';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { css, SerializedStyles } from '@emotion/react';

import t from 'react-translate';
import { AngularContext } from 'react-app';
import { NvTooltip } from 'shared/components/nv-tooltip';
import { black, primary } from 'styles/global_defaults/colors';
import { Institution } from 'redux/schemas/models/institution';
import { semiBoldFontWeight } from 'styles/global_defaults/fonts';
import { screenXsMax } from 'styles/global_defaults/media-queries';
import { MentoringProgram } from 'redux/schemas/models/mentoring-programs';
import ManageProgramAccessModal from 'athena/components/manage-program-access-modal';
import MentoringProgramRowOptions from 'athena/components/mentoring-program-row-options';
import {
  halfSpacing,
  tripleSpacing,
  standardSpacing,
  createGridStyles,
} from 'styles/global_defaults/scaffolding';
import {
  BKG_ROW_Z_INDEX,
  NvTableRowProps,
  NvTableCellProps,
  useIsRowHovered,
} from 'shared/components/nv-responsive-table';
import { config } from '@config/pendo.config.json';

export type MentoringProgramRowExtraProps = { institution: Institution };

export type MentoringProgramCellProps = NvTableCellProps<MentoringProgram, unknown, MentoringProgramRowExtraProps> & {
  program: MentoringProgram,
  optionsCellRef?: React.MutableRefObject<HTMLDivElement>,
  isBeingDeleted?: boolean,
};

const commonStyles = css`
  height: 100%;
  display: flex;
  align-items: center;
`;

const nameStyles = css`
  ${commonStyles};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${halfSpacing}px;
  padding-bottom: ${halfSpacing}px;

  &:not(.disabled) {
    pointer-events: none; /* Allows events to pass through the bkg row. */
    /* Place above the bkg row to take mouse over focus */
    z-index: ${BKG_ROW_Z_INDEX + 1} !important;
  }

  /* TODO: Move this into the NvAvatar */
  .name-panel {
    margin-left: ${halfSpacing}px;
    flex-direction: column;
    align-items: flex-start;

    .catalog-id {
      word-break: break-word;
    }
  }

  &:not(.disabled) {
    .program-name {
      pointer-events: all;
    }

    .program-name:hover {
      color: ${primary};

      & ~ .created-by-name {
        display: block;
      }
    }
  }

  /* Displayed on hover */
  .created-by-name {
    display: none;
  }

  grid-column: 1;

  .program-name-btn-link {
    padding: 0;
    color: ${black};
    text-decoration: none;
    font-weight: ${semiBoldFontWeight};

    &:hover,
    &:focus {
      color: ${primary};
    }

    &:focus {
      border-radius: unset;
      outline-offset: 0;
    }
  }
`;
const releaseDateStyles = css`
${commonStyles};
  width: 100%;
`;

const closeDateStyles = css`
  ${commonStyles};
`;

const optionsStyles = css`
  ${commonStyles};
`;


const MentoringProgramRow = (props: NvTableRowProps<MentoringProgram, unknown, MentoringProgramRowExtraProps>) => {
  const optionCellRef = React.useRef<HTMLDivElement>(null);

  const cells: [(props: MentoringProgramCellProps) => JSX.Element, SerializedStyles][] = [
    [NameCell, nameStyles],
    [ReleaseDateCell, releaseDateStyles],
    [CloseDateCell, closeDateStyles],
    [OptionsCell, optionsStyles],
  ];

  const mobileCells = [cells[0], cells[cells.length - 1]];

  const program = _.clone(props.data);

  const isHandheld = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  const rowCells = isHandheld ? mobileCells : cells;

  return (
    <React.Fragment>
      {
        rowCells.map((cell, i) => {
          const cellProps: MentoringProgramCellProps = {
            ...props,
            program,
            reactKey: `${props.rowIndex}-${i + 1}`,
            serializedStyles: cell[1],
            divProps: {
              style: createGridStyles(i + 1, props.rowIndex, i + 2, props.rowIndex + 1),
            },
            // Overwrite the props.disabled with the local state so it can be dynamically updated
            disabled: program.isBeingDeleted,
            optionsCellRef: optionCellRef,
            rowClasses: program.isBeingDeleted ? ' disabled' : '',
          };
          return cell[0](cellProps);
        })
      }
    </React.Fragment>
  );
};

const NameCell = (props: MentoringProgramCellProps) => {
  const { program } = props;

  const { injectServices } = React.useContext(AngularContext);
  const [$state] = injectServices(['$state'])
  const [showManageAccess, setShowManageAccess] = React.useState(false);
  const goToMentoringProgramUrl = $state.href('mentoring-program-root', {
    programId: program.id,
  });
  const isHover = useIsRowHovered(props.rowKey);

  // Without the program image each row looks thinner, so here forcing to have
  // the specific height.
  const cellStyles = css`
    ${props.serializedStyles};
    height: ${tripleSpacing + standardSpacing}px;
    ${isHover && css`
      .name-panel .view-primary-link {
        opacity: 1;
      }

      .created-by-name {
        display: block;
      }
    `};
  `;

  const ariaLabel = t.MENTORING_PROGRAMS.ARIA_LABEL.ACCESS_PROGRAM(program?.name);

  // Open program access modal if user is not enrolled in course, otherwise redirect it to program home
  const renderLinkOrButton = program.isEnrolled
    ? (
      <a
        target='_blank'
        rel='noopener noreferrer'
        href={goToMentoringProgramUrl}
        data-qa={config.pendo.athena.mentorshipProgram.home}
        data-qa-id={`${config.pendo.athena.mentorshipProgram.home}_${program?.id}`}
        aria-label={ariaLabel}
      >
        {program.name}
      </a>
    )
    : (
      <Button
        variant='link'
        onClick={() => setShowManageAccess(true)}
        className='program-name-btn-link text-left border-0'
        data-qa={config.pendo.athena.mentorshipProgram.home}
        data-qa-id={`${config.pendo.athena.mentorshipProgram.home}_${program?.id}`}
        aria-label={ariaLabel}
      >
        {program.name}
      </Button>
    );

  return (
    <div
      {...props.divProps}
      css={cellStyles}
      key={props.reactKey}
      className={`name-cell${props.rowClasses ?? ''}`}
    >
      <ManageProgramAccessModal
        onClose={() => setShowManageAccess(false)}
        programId={showManageAccess ? program.id : undefined}
      />
      <div className='name-panel'>
        {/* Deletion text that appears when a course is being deleted */}
        { program.isBeingDeleted && <div className='text-small font-weight-bold text-warning'>{t.COURSES.DASHBOARD_TABLE.DELETION_IN_PROGRESS()}</div> }
        {/* Clickable program name that navigates to program home page */}
        <div className='page-title-xs font-weight-bold black program-name'>
          <NvTooltip
            placement='top'
            preventOverflow={false}
            enabled={!props.disabled}
            text={t.MENTORING_PROGRAMS.VISIT_PROGRAM()}
          >
            {props.disabled ? program.name : renderLinkOrButton}
          </NvTooltip>
        </div>
        <div className='created-by-name text-small font-weight-bold'>
          {t.COURSES.DASHBOARD_TABLE.CREATED_BY(program.createdBy?.fullName || 'NovoEd Team')}
        </div>
      </div>
    </div>
  );
};

const ReleaseDateCell = (props: MentoringProgramCellProps) => {
  const { program } = props;
  return (
    <div className={`release-date-cell${props.rowClasses}`} css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      {program.releasedDate && moment(program.releasedDate).format('L')}
    </div>
  );
};

const CloseDateCell = (props: MentoringProgramCellProps) => {
  const { program, extraObject } = props;
  const isBeingCloned = !_.isEmpty(extraObject);
  let closeDateText = '';

  if (program.endDate && !isBeingCloned) {
    closeDateText = moment(program.endDate).format('L');
  }

  return (
    <div className={`close-date-cell${props.rowClasses}`} css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      {closeDateText}
    </div>
  );
};

const OptionsCell = (props: MentoringProgramCellProps) => (
  <MentoringProgramRowOptions cellProps={props} key={props.reactKey} />
);

export default MentoringProgramRow;
