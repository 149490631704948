import { css } from '@emotion/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AngularContext } from 'react-app';
import t from 'react-translate';
import MenteeCard from 'athena/components/mentoring-program/home/participant/mentee-card';
import MentoringProgramContext, { MentoringProgramHomeUIContext } from 'athena/components/mentoring-program/context';
import ProgramHeader from 'athena/components/program-header/program-header';
import { almostWhite } from 'athena/styles/colors';
import { white } from 'styles/global_defaults/colors';
import {
  standardSpacing,
  largeSpacing,
  halfSpacing,
  tripleSpacing,
} from 'styles/global_defaults/scaffolding';
import { MentoringProgramEnrollment } from 'redux/schemas/models/mentoring-program-enrollments';
import { getMentoringProgramAliases } from 'redux/selectors/mentoring-programs';
import { getMentoringProgramEnrollment } from 'redux/selectors/mentoring-program-enrollments';
import { withOverflowFlexGrowClass } from './constants';
import MatchCelebration from './home/participant/match-celebration';
import moment from 'moment';
import _ from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { screenMdMin } from 'styles/global_defaults/media-queries';

const COMPACT_HEADER_MINIMUM_SCROLL = 20;

const getPaddingBottom = (isCompactHeader, isTablet) => {
  if (isTablet) return tripleSpacing;
  return isCompactHeader ? standardSpacing : 320;
}

const MyMenteesList = () => {
  const [innerScroll, setInnerScroll] = useState(0);
  const scrollContainerRef = useRef<HTMLDivElement>();
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);

  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { mentorshipProgramEnrollment: enrollmentId, endDate, matchAnnouncementDate, releasedDate } = mentoringProgram || {};
  const mentorshipProgramEnrollment: MentoringProgramEnrollment = useSelector(state => (
    getMentoringProgramEnrollment(state, enrollmentId)
  ));
  const { assignedConnections } = mentorshipProgramEnrollment || {};
  const hasMultipleMentees = assignedConnections?.length > 1;
  const isClosed = endDate && moment().isAfter(moment(endDate));
  const isNotReleased = releasedDate && moment().isBefore(moment(releasedDate));
  const matchAnnouncementDateIsPast = matchAnnouncementDate && moment().isAfter(moment(matchAnnouncementDate));

  const mentoringProgramAliases = useSelector((state) => getMentoringProgramAliases(state, mentoringProgram?.id));

  const containerTranslate = (30 / 100) * scrollPercentage;
  const isCompactHeader = scrollPercentage > COMPACT_HEADER_MINIMUM_SCROLL;

  const isTablet = useMediaQuery({
    query: `(max-width: ${screenMdMin}px)`,
  });

  const styles = css`
    flex: 1;
    overflow: hidden;
    ${innerScroll === 0 && css`
      overflow-y: auto;
    `};

    .scroll-content {
      height: fit-content;
      min-height: 100%;
      background-color: ${almostWhite};
    }

    .my-mentees {
      height: calc(100% - ${getPaddingBottom(isCompactHeader, isTablet)}px);
    }

    .mentee-list {
      max-height: calc(100% + 60px);
      overflow-y: scroll;
      background-color: ${white};
      width: fit-content;
      margin-left: ${standardSpacing}px;
      padding: ${largeSpacing}px;
      transform: translateY(-${120 - containerTranslate}px);
      border-radius: ${halfSpacing}px;
      box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 10%);
    }
  `;

  const uiContextValue = {
    withOverflowFlexGrowClass,
  };

  useEffect(() => {
    // If program is closed or is not released, or the matches haven't been announced yet, redirect to program root
    if ((isClosed || isNotReleased || !matchAnnouncementDateIsPast) && mentoringProgram?.id) {
      $state.go('mentoring-program-root', { programId: mentoringProgram.id });
    }
  }, [isClosed, isNotReleased, matchAnnouncementDateIsPast]);

  return (
    <MentoringProgramHomeUIContext.Provider value={uiContextValue}>
      <div
        css={styles}
        ref={scrollContainerRef}
        onScroll={() => {
          const { clientHeight, scrollHeight, scrollTop } = scrollContainerRef.current;
          const total = scrollHeight - clientHeight;
          setScrollPercentage((100 / total) * scrollTop);
        }}
      >
        <div
          className='scroll-content'
        >
          <ProgramHeader
            scrollPercentage={scrollPercentage}
            compactHeaderScrollPercentage={COMPACT_HEADER_MINIMUM_SCROLL}
          />
          {!_.isEmpty(mentoringProgramAliases) && (
            <div className='my-mentees'>
              <div className='mentee-list'>
                <div
                  className='heading-3'
                  onScroll={(e) => setInnerScroll(e.currentTarget.scrollTop)}
                >
                  {t.MENTORING_PROGRAMS.MY_MENTEES(mentoringProgramAliases)}
                </div>
                {assignedConnections?.map((connection) => (
                  <MenteeCard
                    id={connection.connectionId}
                    programId={mentoringProgram.id}
                    user={connection.assignedEnrollment.user}
                  />
                ))}
                {hasMultipleMentees && <MatchCelebration />}
              </div>
            </div>
          )}
        </div>
      </div>
    </MentoringProgramHomeUIContext.Provider>
  );
};

export default MyMenteesList;
