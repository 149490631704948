import { css } from '@emotion/react';
import CollapsiblePanel from 'athena/components/collapsible-panel';
import t from 'react-translate';
import { MentorshipProgramProfileAnswer } from 'redux/schemas/models/mentoring-program-profile-answers';
import { ProgramLevelQuestion } from 'redux/schemas/models/mentoring-program-profile-questions';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { config } from '@config/pendo.config.json';

type ProgramFieldProps = {
  field: MentorshipProgramProfileAnswer;
};

const ProgramField = ({ field }: ProgramFieldProps) => {
  const { profileQuestion, answer } = field;
  const { questionList } = (profileQuestion as ProgramLevelQuestion) || {};
  const { questionText } = questionList || {};

  const hasAnswer = answer?.length;

  return hasAnswer ? (
    <div className='mt-4'>
      <div className='text-small gray-2 mb-1'>{questionText}</div>
      <div className='text-regular heading-5 mb-2'>
        {Array.isArray(answer) ? answer.join(', ') : answer}
      </div>
    </div>
  ) : null;
};

type ProgramLevelInfoProps = {
  endRef: (node?: Element | null) => void;
  programInfo: MentorshipProgramProfileAnswer[];
};

const styles = css`
  border-radius: 0 0 ${halfSpacing}px ${halfSpacing}px;
`;

const ProgramLevelInfo = ({ endRef, programInfo }: ProgramLevelInfoProps) => {
  const panelTitle = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.CONNECTION_DETAILS.PROGRAM_PROFILE.TITLE();

  return programInfo?.length ? (
    <div css={styles} className='px-5 py-4 info-container'>
      <CollapsiblePanel
        isNested
        title={panelTitle}
        iconSize='smallest'
        ariaLabel={panelTitle}
        dataQa={
          config.pendo.athena.mentorshipProgram.participantHome.connectionCard
            .programLevelInfo
        }
      >
        <>
          <div className='mt-4'>
            {programInfo?.map((field) => (
              <ProgramField key={field.id} field={field} />
            ))}
          </div>
          <div ref={endRef} />
        </>
      </CollapsiblePanel>
    </div>
  ) : null;
};

export default ProgramLevelInfo;
