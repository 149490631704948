import t from 'react-translate';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  CompletionStatus,
  States,
  StepKey,
} from 'redux/schemas/models/mentoring-program-enrollments';
import { config } from '@config/pendo.config.json';
import ProfileModal from './profile-modal';
import RequiredQuestionsModal from '../../required-questions-modal';

type CompleteProfileButtonProps = {
  states: States;
}

const CompleteProfileButton = ({ states }: CompleteProfileButtonProps) => {
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [showRequiredQuestionsModal, setShowRequiredQuestionsModal] = useState<boolean>(false);
  const [remainingQuestions, setRemainingQuestions] = useState(0);
  const buttonRef = useRef(null);

  const getButtonTitle = () => {
    // Default button title is 'Get Started'
    // If Review Profile is in progress, button title is 'Continue'
    // If Profile Completion and Review Profile are completed, button title is 'Edit Profile'
    let buttonTitle = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.CTA_BUTTON.GET_STARTED();
    if (
      states[StepKey.PROFILE_COMPLETION]?.status === CompletionStatus.COMPLETED
      && states[StepKey.PROFILE_REVIEW]?.status === CompletionStatus.COMPLETED
    ) {
      buttonTitle = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.CTA_BUTTON.EDIT_PROFILE();
    } else if (states[StepKey.PROFILE_REVIEW]?.status === CompletionStatus.IN_PROGRESS) {
      buttonTitle = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.CTA_BUTTON.CONTINUE();
    }
    return buttonTitle;
  }

  useEffect(() => {
    if (!showProfileModal) {
      buttonRef.current?.blur();
    }
  }, [showProfileModal]);

  const buttonLabel = getButtonTitle();

  return (
    <>
      <Button
        size='lg'
        onClick={() => {
          setShowProfileModal(true);
        }}
        data-qa={config.pendo.athena.mentorshipProgram.participantHome.steps.profile.show}
        ref={buttonRef}
      >
        {buttonLabel}
      </Button>
      {showProfileModal && (
        <ProfileModal
          title={t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.TITLE.DEFAULT()}
          setShowModal={setShowProfileModal}
          onClose={(remaining, showModal) => {
            setRemainingQuestions(remaining);
            setShowRequiredQuestionsModal(showModal);
          }}
        />
      )}
      {showRequiredQuestionsModal && (
        <RequiredQuestionsModal
          show={showRequiredQuestionsModal}
          remainingQuestions={remainingQuestions}
          onClose={() => {
            setShowRequiredQuestionsModal(false);
          }}
        />
      )}
    </>
  );
};

export default CompleteProfileButton;
