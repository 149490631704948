import React, { useCallback, useEffect, useState, useMemo } from 'react';
import t from 'react-translate';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import Tab from 'dashboard/components/tab';
import ClickableContainer from 'components/clickable-container';
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';
import NvResponsiveTable, { borderStyle, NvResponsiveTableColumn } from 'shared/components/nv-responsive-table';
import { NvSearchBar } from 'shared/components/nv-search-bar';
import LoadingWrapper, { LoaderType } from 'shared/components/loading-wrapper';
import NvIcon from 'shared/components/nv-icon';
import NvDropdown, { NvDropdownAlign, NvDropdownButtonStyle, NvDropdownTextItem } from 'shared/components/inputs/nv-dropdown';

import { useAppDispatch } from 'redux/store';
import { RootState } from 'redux/schemas';
import { PagedDataQueryParams } from 'redux/create-action-creators';
import { Course, CoursesNormalized } from 'redux/schemas/models/course';
import { CourseAdminDashboardState } from 'redux/schemas/models/course-admin';
import { CurrentLearningPermission, getCurrentLearning } from 'redux/actions/courses';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { getCourseAdminDashboardState } from 'redux/selectors/course-admin-dashboard';
import DashboardLoadingRow, { loadingWrapperStyles } from 'shared/components/dashboard-loading-row';

import { black, gray1, gray5, gray7, info, kelp, primary, warning, white, yellow } from 'styles/global_defaults/colors';
import { doubleSpacing, halfSpacing, largeSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { handheld, notDesktop } from 'styles/global_defaults/media-queries';
import { textLargeBodyFontSize, textMediumFontSize } from 'styles/global_defaults/fonts';

import {
  ReactComponent as LastVisitedIcon,
} from 'styles/icons/course-admin-tab-last-visited.svg';
import {
  ReactComponent as ActiveIcon,
} from 'styles/icons/course-admin-tab-active.svg';
import {
  ReactComponent as FutureIcon,
} from 'styles/icons/course-admin-tab-future.svg';
import {
  ReactComponent as PastIcon,
} from 'styles/icons/course-admin-tab-past.svg';
import {
  ReactComponent as DemoIcon,
} from 'styles/icons/course-admin-tab-demo.svg';
import CourseRow, { CourseRowExtraProps } from './course-row';

import { SortAttr, SortDirection } from './types';

enum TabName {
  LAST_VISITED = 'LAST_VISITED',
  ACTIVE = 'ACTIVE',
  FUTURE = 'FUTURE',
  PAST = 'PAST',
  DEMO = 'DEMO',
}

enum TabFilter {
  LAST_ACTIVE = 'last_active',
  ACTIVE = 'active',
  FUTURE = 'future',
  PAST = 'past',
  DEMO = 'demo',
  PRIMARY = 'primary',
  PRODUCTION = 'production',
}

const cellsWidth = {
  name: '40%',
  sortDate: '20%',
  numEnrollees: '12%',
  completionRate: '28%',
};

const renderIcon = (IconComponent: React.ComponentType<React.SVGProps<SVGElement>>) => (
  <div>
    <IconComponent
      width={doubleSpacing}
      height={doubleSpacing}
    />
  </div>
);

const composeSortAttribute = (sortAttr: SortAttr, isSortAsc: boolean) => `${sortAttr}_${isSortAsc ? SortDirection.ASC : SortDirection.DESC}`;

const initialSortAttribute = SortAttr.LAST_ACTIVITY_DATE;

const primaryTabFilters = {
  [TabFilter.PRIMARY]: '1',
  [TabFilter.PRODUCTION]: '1',
};

const initialFilterFetchParams: PagedDataQueryParams['filters'] = {
  [TabFilter.LAST_ACTIVE]: '1',
  [composeSortAttribute(initialSortAttribute, false)]: '1',
  ...primaryTabFilters,
};


const getTabFetchParams = (tab: TabName) => {
  switch (tab) {
    case TabName.LAST_VISITED:
      return {
        [TabFilter.LAST_ACTIVE]: '1',
        ...primaryTabFilters,
      };
    case TabName.ACTIVE:
      return {
        [TabFilter.ACTIVE]: '1',
        ...primaryTabFilters,
      };
    case TabName.FUTURE:
      return {
        [TabFilter.FUTURE]: '1',
        ...primaryTabFilters,
      };
    case TabName.PAST:
      return {
        [TabFilter.PAST]: '1',
        ...primaryTabFilters,
      };
    case TabName.DEMO:
      return {
        [TabFilter.DEMO]: '1',
      };
    default:
      return {};
  }
};

const removeSortParamsFromFilters = (filters: PagedDataQueryParams['filters']) => Object.keys(filters)
  .filter(key => !Object.values(SortAttr).some(sortAttr => key.startsWith(sortAttr)))
  .reduce((obj, key) => {
    obj[key] = filters[key];
    return obj;
  }, {});

const removeTabFilterParamsFromFilters = (filters: PagedDataQueryParams['filters']) => Object.keys(filters)
  .filter(key => !Object.values(TabFilter).includes(key as TabFilter))
  .reduce((obj, key) => {
    obj[key] = filters[key];
    return obj;
  }, {});

function CourseAdminDashboard() {
  const dispatch = useAppDispatch();
  const [currentTab, setCurrentTab] = React.useState<TabName>(TabName.LAST_VISITED);
  const [pagedFetchParams, setPagedFetchParams] = useState<PagedDataQueryParams>({ filters: initialFilterFetchParams });
  const [selectedSortAttr, setSelectedSortAttr] = useState<SortAttr>(initialSortAttribute);
  const [isSortAsc, setIsSortAsc] = useState(false);
  const [isCountsLoading, setIsCountsLoading] = useState(true);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [containerRef, setContainerRef] = useState(null);
  const [hasResults, setHasResults] = useState(false);
  const institution = useSelector((state) => getCurrentInstitution(state));
  const getStoreCourseData = (state: RootState) => state.models.courses as CoursesNormalized;
  const courseAdminDashboardState: CourseAdminDashboardState = useSelector(getCourseAdminDashboardState);

  const fetchParams = {
    permission: CurrentLearningPermission.COURSE_ADMIN,
  };

  const setCurrentTabFetchParams = (tab: TabName) => {
    const tabFilterFetchParams = getTabFetchParams(tab);

    setPagedFetchParams(currFetchParams => {
      const newFiltersFetchParams = removeTabFilterParamsFromFilters(currFetchParams.filters);

      return {
        filters: {
          ...newFiltersFetchParams,
          ...tabFilterFetchParams,
        },
      };
    });
  };

  const onRefChange = useCallback(node => setContainerRef(node), []);

  const hasUserScrolledDown = useInfiniteScroll(
    containerRef?.firstChild as HTMLDivElement,
    standardSpacing,
    false,
  );

  const handleSearch = (searchQuery: string) => {
    if (searchQuery) {
      setIsSearchActive(true);
      setPagedFetchParams(currPagedFetchParams => {
        const newFiltersFetchParams = removeTabFilterParamsFromFilters(currPagedFetchParams.filters);

        return {
          ...currPagedFetchParams,
          filters: {
            ...newFiltersFetchParams,
          },
          searchQuery,
        };
      });
    }
  };

  const handleClearSearch = () => {
    setIsSearchActive(false);
    setCurrentTabFetchParams(currentTab);
  };

  const handleTabClick = (tabName: TabName) => {
    setCurrentTab(tabName);
    setCurrentTabFetchParams(tabName);
  };

  const toggleSortDirection = (e) => {
    e.stopPropagation();
    const newSortDirection = !isSortAsc;
    const newSelectedSort = composeSortAttribute(selectedSortAttr, newSortDirection);

    setIsSortAsc(newSortDirection);
    setPagedFetchParams(currPagedFetchParams => {
      const newFiltersFetchParams = removeSortParamsFromFilters(currPagedFetchParams.filters);

      return {
        ...currPagedFetchParams,
        filters: {
          ...newFiltersFetchParams,
          [newSelectedSort]: '1',
        },
      };
    });
  };

  const disableTabs = Boolean(pagedFetchParams.searchQuery);

  const tabs = useMemo(() => [
    {
      name: TabName.LAST_VISITED,
      icon: LastVisitedIcon,
      title: t.COURSE_ADMIN_DASHBOARD.TABS.LAST_VISITED(),
      color: primary,
      count: courseAdminDashboardState.counts.lastActive,
    },
    {
      name: TabName.ACTIVE,
      icon: ActiveIcon,
      title: t.COURSE_ADMIN_DASHBOARD.TABS.ACTIVE(),
      color: kelp,
      count: courseAdminDashboardState.counts.active,
    },
    {
      name: TabName.FUTURE,
      icon: FutureIcon,
      title: t.COURSE_ADMIN_DASHBOARD.TABS.FUTURE(),
      color: yellow,
      count: courseAdminDashboardState.counts.future,
    },
    {
      name: TabName.PAST,
      icon: PastIcon,
      title: t.COURSE_ADMIN_DASHBOARD.TABS.PAST(),
      color: gray1,
      count: courseAdminDashboardState.counts.past,
    },
    {
      name: TabName.DEMO,
      icon: DemoIcon,
      title: t.COURSE_ADMIN_DASHBOARD.TABS.DEMO(),
      color: gray1,
      count: courseAdminDashboardState.counts.demo,
      tabClassName: TabFilter.DEMO,
    },
  ], [courseAdminDashboardState.counts]);

  const displayAllTabs = tabs.every(tab => tab.count > 0);

  const headerCellTop = React.useMemo(() => {
    const scrolledDown = (isSearchActive && hasResults) ? 250 : 194;
    const nonScrolledDown = (isSearchActive && hasResults) ? 274 : 224;

    return hasUserScrolledDown ? scrolledDown : nonScrolledDown;
  }, [hasResults, isSearchActive, hasUserScrolledDown]);

  const styles = css`
    z-index: 0;
    width: 100%;
    position: relative;
    height: calc(100vh - 145px);
    background: linear-gradient(315.42deg, rgba(220, 253, 253, 0.3) 0%, rgba(247, 251, 232, 0.3) 99.28%), ${white};
    display: flex;
    justify-content: center;

    .dashboard-header-container {
      top: 0;
      z-index: 2;
      position: absolute;
      transition: all 0.5s;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: inherit;

      .dashboard-tabs {
        width: 100%;
        max-width: 1000px;
        display: flex;
        justify-content: ${displayAllTabs ? 'space-between' : 'center'};
        gap: ${doubleSpacing}px;
        padding: ${largeSpacing}px 0 ${doubleSpacing}px;

        ${handheld(css`
          overflow-x: auto;
          justify-content: flex-start;
          gap: ${standardSpacing}px;
          padding: ${largeSpacing}px ${standardSpacing}px ${doubleSpacing}px;
        `)};
      }

      .tab-container {
        opacity: ${disableTabs ? 0.5 : 1};
        pointer-events: ${disableTabs ? 'none' : 'all'};

        > div {
          height: ${hasUserScrolledDown ? '70px' : '100px'};
        }
      }

      .dashboard-actions {
        width: 100%;
        max-width: 1000px;
        margin-bottom: ${standardSpacing}px;

        ${notDesktop(css`
          padding: 0 ${standardSpacing}px;
        `)}

        .nv-search-bar {
          background-color: ${gray7};
          width: 180px;
          padding: ${quarterSpacing + 2}px ${halfSpacing}px;

          .search-button {
            .icon {
              font-size: ${textMediumFontSize + 2}px;
            }
          }

          input {
            margin-left: ${halfSpacing}px;
          }
        }

        .search-active-container {
          display: flex;
          align-items: center;
          gap: ${quarterSpacing}px;

          .search-tag {
            width: fit-content;
            display: flex;
            align-items: center;
            gap: ${quarterSpacing}px;
            background-color: ${info};
            border: 1px solid ${primary};
            padding: ${quarterSpacing}px ${halfSpacing}px;
            border-radius: ${largeSpacing}px;
            color: ${primary};
          }
        }
        .search-summary{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: ${black};
          margin-top: ${halfSpacing}px;
          .summary-label {
            font-size: ${textLargeBodyFontSize}px;
          }
        }
      }
    }

    .dashboard-content {
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      ${notDesktop(css`
        padding: 0 ${standardSpacing}px;
      `)}

      .dashboard-tab-content {
        height: 100%;
        width: 100%;
        overflow-y: auto;
        padding-bottom: ${standardSpacing}px;

        .nv-responsive-table.course-admin-dashboard-table {
          display: flex;
          flex-direction: column;
          align-items: center;

          ${handheld(css`
            overflow-x: hidden;
          `)}

          .grid {
            padding-top: ${headerCellTop}px;
            width: 100%;
            max-width: 1000px;

            ${handheld(css`
              grid-template-columns: 100%;
            `)};

            .bkg-row {
              cursor: pointer;
              border-bottom: 1px solid ${gray5};

              &:hover {
                box-shadow: none;
                border-color: ${warning};
              }
            }

            .header-cell {
              top: ${headerCellTop}px;
              background: linear-gradient(315.42deg, rgba(220, 253, 253, 0.3) 0%, rgba(247, 251, 232, 0.3) 99.28%), ${white};
              border-bottom: 1px solid ${gray5};
              border-top: none;

              ${handheld(css`
                display: none;
              `)}

              .nv-dropdown {
                .table-header-sort-date {
                  &.active {
                    color: ${primary};
                  }
                }
              }

              .sort-container {
                display: flex;
              }
            }

            div[class*="-cell"] {
              &.name-cell {
                border-color: transparent;
                pointer-events: none;
                z-index: 2;

                .course-name {
                  pointer-events: all;
                }
              }
            }
          }

          .no-results-panel {
            padding-top: 224px;
          }
        }
      }
    }
  `;

  const sortOptions = [
    SortAttr.LAST_ACTIVITY_DATE,
    SortAttr.RELEASE_DATE,
    SortAttr.START_DATE,
    SortAttr.END_DATE,
    SortAttr.CLOSE_DATE,
  ];

  const sortOptionTranslation = {
    [SortAttr.LAST_ACTIVITY_DATE]: t.COURSE_ADMIN_DASHBOARD.SORTING.LAST_ACTIVITY(),
    [SortAttr.RELEASE_DATE]: t.COURSE_ADMIN_DASHBOARD.SORTING.RELEASE_DATE(),
    [SortAttr.START_DATE]: t.COURSE_ADMIN_DASHBOARD.SORTING.START_DATE(),
    [SortAttr.END_DATE]: t.COURSE_ADMIN_DASHBOARD.SORTING.END_DATE(),
    [SortAttr.CLOSE_DATE]: t.COURSE_ADMIN_DASHBOARD.SORTING.CLOSE_DATE(),
  };

  const sortDropdownOptions: NvDropdownTextItem[] = sortOptions.map(sortAttr => ({
    type: 'text',
    id: SortAttr[sortAttr],
    text: sortOptionTranslation[sortAttr],
    callback: () => {
      setSelectedSortAttr(sortAttr);
      setIsSortAsc(false);
      setPagedFetchParams(currPagedParams => {
        const filtersWithoutSortParams = removeSortParamsFromFilters(currPagedParams.filters);
        const selectedSort = composeSortAttribute(sortAttr, false);

        return {
          ...currPagedParams,
          filters: {
            ...filtersWithoutSortParams,
            [selectedSort]: '1',
          },
        };
      });
    },
  }));

  const sortColumnName = selectedSortAttr ? sortOptionTranslation[selectedSortAttr] : sortOptionTranslation.release_date;

  const customSortDropdownTarget = () => (
    <React.Fragment>
      <div className={`table-header-sort-date ${selectedSortAttr ? 'active' : ''}`}>
        {sortColumnName}
      </div>
      {selectedSortAttr && (
        <ClickableContainer
          onClick={toggleSortDirection}
          data-qa='course-admin-dashboard-sort-direction'
        >
          <span className={`icon icon-xss-smallest icon-sorting-${isSortAsc ? 'up' : 'down'}-active`} />
        </ClickableContainer>
      )}
    </React.Fragment>
  );

  const sortDropdown = (
    <div className='sort-container'>
      <NvDropdown
        drop='down'
        items={sortDropdownOptions}
        minWidth={150}
        showSelectedIndicator
        align={NvDropdownAlign.CENTER}
        initialIndex={sortOptions.indexOf(initialSortAttribute)}
        buttonStyle={NvDropdownButtonStyle.CUSTOM}
        offset={12}
        customTarget={customSortDropdownTarget}
      />
    </div>
  );

  const createTableColumns: () => NvResponsiveTableColumn[] = () => [
    {
      name: t.COURSE_ADMIN_DASHBOARD.TABLE.NAME(),
      className: 'name-cell',
      gridWidth: cellsWidth.name,
    },
    {
      name: sortColumnName,
      className: 'sort-date-cell',
      gridWidth: cellsWidth.sortDate,
      content: sortDropdown,
    },
    {
      name: t.COURSE_ADMIN_DASHBOARD.TABLE.NUM_OF_ENROLLEES(),
      className: 'num-enrollees-cell',
      gridWidth: cellsWidth.numEnrollees,
    },
    {
      name: t.COURSE_ADMIN_DASHBOARD.TABLE.COMPLETION_RATE(),
      className: 'completion-rate-cell',
      gridWidth: cellsWidth.completionRate,
    },
  ];

  const tableColumns = createTableColumns();

  const SearchSummary = () => (
    <div className='search-summary'>
      <div className='summary-label' data-qa='course-admin-dashboard-summary-label'>{t.COURSE_ADMIN_DASHBOARD.SEARCH_RESULTS()} <span className='font-weight-bold black'>{pagedFetchParams.searchQuery}</span></div>
      <ClickableContainer onClick={handleClearSearch} data-qa='course-admin-dashboard-clear-search-panel'>
        <div className='label mt-2 text-primary font-weight-bold'>{t.COURSE_ADMIN_DASHBOARD.CLEAR_SEARCH()}</div>
      </ClickableContainer>
    </div>
  );

  useEffect(() => {
    dispatch(getCurrentLearning({
      permission: CurrentLearningPermission.COURSE_ADMIN,
      count_only: 1,
    })).then(() => setIsCountsLoading(false));
  }, [dispatch]);

  useEffect(() => {
    // Change default tab if last_active tab count is empty
    if (tabs[0].count === 0) {
      const displayTabs = tabs.filter(tab => tab.count > 0);

      if (displayTabs.length > 0) {
        setCurrentTab(displayTabs[0].name);
        setPagedFetchParams(currPagedParams => ({
          ...currPagedParams,
          filters: {
            ...removeTabFilterParamsFromFilters(currPagedParams.filters),
            ...getTabFetchParams(displayTabs[0].name),
          },
        }));
      }
    }
  }, [courseAdminDashboardState, tabs]);

  return (
    <div css={styles}>
      <LoadingWrapper
        keepRendered={false}
        loaderType={LoaderType.PLACEHOLDER}
        isLoaded={!isCountsLoading}
        css={loadingWrapperStyles}
      >
        <div className='dashboard-header-container'>
          <div className='dashboard-tabs'>
            {tabs.map((tab) => (tab.count > 0 ? (
              <ClickableContainer
                key={tab.name}
                disabled={disableTabs}
                isFocusable={!disableTabs}
                className={`tab-container ${currentTab === tab.name ? 'active' : ''} ${tab.tabClassName ?? ''}`}
                onClick={() => handleTabClick(tab.name)}
                data-qa={`course-admin-dashboard-tab-${tab.name.toLowerCase()}`}
              >
                <Tab
                  color={tab.color}
                  count={tab.count}
                  title={tab.title}
                  tooltipText={tab.title}
                  isCompact={hasUserScrolledDown}
                  isSelected={currentTab === tab.name}
                  descriptionItems={[]}
                  renderImage={() => renderIcon(tab.icon)}
                />
              </ClickableContainer>
            ) : null
            ))}
          </div>
          <div className='dashboard-actions'>
            {isSearchActive ? (
              <>
                <div className='search-active-container'>
                  <i className='icon icon-small icon-search text-gray-2' />
                  <div className='search-tag'>
                    {pagedFetchParams.searchQuery}
                    <NvIcon
                      icon='close'
                      size='xss-smallest'
                      onClick={handleClearSearch}
                      data-qa='course-admin-dashboard-clear-search'
                    />
                  </div>
                </div>
                {hasResults && <SearchSummary />}
              </>
            ) : (
              <NvSearchBar
                name='search-course'
                onSearch={handleSearch}
                placeholder={t.COURSE_ADMIN_DASHBOARD.TABLE.SEARCH()}
                data-qa='course-admin-dashboard-search'
              />
            )}
          </div>
        </div>
        <div className='dashboard-content'>
          <div className='dashboard-tab-content' ref={onRefChange}>
            <NvResponsiveTable<Course, CourseRowExtraProps>
              className='course-admin-dashboard-table'
              columns={tableColumns}
              fetchData={getCurrentLearning}
              fetchParams={fetchParams}
              pagedFetchParams={pagedFetchParams}
              currentPageNameParam='page'
              currentPageSizeNameParam='page_size'
              currentSearchQueryNameParam='text'
              rowComponent={CourseRow}
              rowProps={{ institution, selectedSortAttr }}
              dataKey='catalogId'
              cacheDataKey='catalogId'
              cacheLookup={getStoreCourseData}
              loadingComponent={DashboardLoadingRow}
              clearSearch={handleClearSearch}
              backgroundColor='transparent'
              noResultsText={null}
              destructureFilters
              noResultsClearTextDataQA='course-admin-dashboard-clear-search-table'
              onResults={setHasResults}
            />
          </div>
        </div>
      </LoadingWrapper>
    </div>
  );
}

export default CourseAdminDashboard;
