import { css } from '@emotion/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { AngularContext } from 'react-app';
import t from 'react-translate';
import { RootState } from 'redux/schemas';
import { MyAccount } from 'redux/schemas/models/my-account';
import { MentoringProgramEnrollment } from 'redux/schemas/models/mentoring-program-enrollments';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { getMentoringProgram } from 'redux/selectors/mentoring-programs';
import { getMentoringProgramEnrollment } from 'redux/selectors/mentoring-program-enrollments';
import { getCurrentUser, getCurrentUserId, getCurrentUserMentoringInfo } from 'redux/selectors/users';
import { getLhsSections } from 'lhs/services/lhs-menu-item-data';
import { useAngularExpression } from 'shared/hooks/use-angular-expression';
import { black } from 'styles/global_defaults/colors';
import { semiBoldFontWeight, textSmallFontSize } from 'styles/global_defaults/fonts';
import LhsMenuSection from './lhs-menu-section';

export const LhsMenuContainer = () => {
  const { $scope, injectServices } = React.useContext(AngularContext);
  const [
    $state,
    $timeout,
    FlyoutModalManager,
    CurrentPermissionsManager,
    CurrentUserManager,
    InteroperabilityRoutes,
  ] = injectServices([
    '$state',
    '$timeout',
    'FlyoutModalManager',
    'CurrentPermissionsManager',
    'CurrentUserManager',
    'InteroperabilityRoutes',
  ]);
  const isExpandedLhs = useAngularExpression(
    () => $state.current.data.expandedLHS,
  );
  const currentStateName = useAngularExpression(() => $state.current.name);
  const currentFlyoutName = useAngularExpression(() => FlyoutModalManager.flyoutName());
  const currentUserMentoringInfo = useSelector(getCurrentUserMentoringInfo);
  const allCourses = useSelector(state => state.models.courses);
  const currentUserId = useSelector(getCurrentUserId);
  const { hasMemberships }: MyAccount = useSelector(getCurrentUser);
  const { discoveryEnabled } = useSelector(getCurrentInstitution);

  const { programId } = $state.params;
  const { mentorshipProgramEnrollment: enrollmentId } = useSelector((state: RootState) => getMentoringProgram(state, programId)) || {};
  const mentorshipProgramEnrollment: MentoringProgramEnrollment = useSelector(state => getMentoringProgramEnrollment(state, enrollmentId)) || {};

  const lhsSections = getLhsSections(
    t,
    $scope,
    $state,
    $timeout,
    isExpandedLhs,
    currentStateName,
    currentFlyoutName,
    FlyoutModalManager,
    CurrentPermissionsManager,
    CurrentUserManager,
    currentUserMentoringInfo,
    InteroperabilityRoutes,
    allCourses,
    currentUserId,
    hasMemberships,
    discoveryEnabled,
    mentorshipProgramEnrollment,
  );

  const stylesBase = css`
    padding-left: 0px;
    font-size: ${textSmallFontSize}px;
    font-weight: ${semiBoldFontWeight};

    .menu-item::marker {
      content: none;
    }

    .menu-item-separator::marker {
      content: none;
    }

    .menu-item-separator {
      border-top: 1px solid ${black};
    }
  `;

  return (
    <ul role='menu' className='lhs-menu-container' css={stylesBase}>
      {lhsSections.length > 0
        && lhsSections.map(({ id, menuItemSeparator, menuItems }) => (
          <LhsMenuSection
            key={id}
            id={id}
            menuItemSeparator={menuItemSeparator}
            menuItems={menuItems}
          />
        ))}
    </ul>
  );
};

export default LhsMenuContainer;
