import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import t from 'react-translate';
import { useSelector, useDispatch } from 'react-redux';
import { AngularContext } from 'react-app';

import Tab from 'dashboard/components/tab';
import ClickableContainer from 'components/clickable-container';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';
import LoadingWrapper, { LoaderType } from 'shared/components/loading-wrapper';
import NvDropdown, {
  NvDropdownAlign,
  NvDropdownButtonStyle,
} from 'shared/components/inputs/nv-dropdown';
import NvResponsiveTable, {
  NvResponsiveTableColumn,
} from 'shared/components/nv-responsive-table';
import { ReactComponent as PendingSubmissions } from 'styles/icons/pending-submission-requests.svg';
import { ReactComponent as ActiveIcon } from 'styles/icons/course-admin-tab-active.svg';
import { gray2, kelp } from 'styles/global_defaults/colors';
import {
  fetchPendingSubmissions,
  fetchScenariosPendingReview,
  fetchSupervisorCourses,
} from 'redux/actions/supervisor-dashboard';
import getSupervisorDashboard, {
  getStorePendingSubmissionsData,
  getSupervisorCoursesData,
} from 'redux/selectors/supervisor-dashboard';
import { VideoPracticeSubmission } from 'redux/schemas/models/video-practice';
import { ScenariosSupervisorDashboard } from 'redux/schemas';
import DashboardLoadingRow, {
  loadingWrapperStyles,
} from 'shared/components/dashboard-loading-row';
import { PagedDataQueryParams } from 'redux/create-action-creators';
import { BasicMentee } from 'redux/schemas/models/course';

import { getCurrentUser } from 'redux/selectors/users';
import { useCourseSupervisorContext } from 'course_supervisor_dashboard/context/course_supervisor_provider';
import { config } from '../../../config/pendo.config.json';

import styles from './styles';
import { DropdownItem, renderIcon, TabName } from './helper';
import PendingSubmissionsRow from './pending-submissions-row';
import SupervisorCourseRow from './supervisor-course-row';

const initialFilterFetchParams: PagedDataQueryParams['filters'] = {
  scenario_id: undefined,
};

const CourseSupervisorDashboard = () => {
  const [hasResults, setHasResults] = useState(false);
  const [hasResultsCourses, setHasResultsCourses] = useState(false);
  const [currentTab, setCurrentTab] = useState<TabName>(
    TabName.SUPERVISOR_COURSES,
  );
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);
  const [pagedFetchParams, setPagedFetchParams] = useState<
  PagedDataQueryParams
  >({ filters: initialFilterFetchParams });
  const [containerRef, setContainerRef] = useState<HTMLDivElement>(null);
  const [containerRefCourses, setContainerRefCourses] = useState<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const {
    isLoadingPendingSubmissions,
    isLoadingScenariosPendingReview,
    isLoadingSupervisorCourses,
  } = useSelector(getSupervisorDashboard);
  const currentUser = useSelector(getCurrentUser);
  const { totalCount: pendingSubmissionsCount, supervisorCoursesTotalCount } = useSelector(state => state.app.supervisorDashboard);
  const {
    scenarioItems,
    selectedScenario,
    onChangeScenarioFilter,
    onClickPendingSubmissionRow,
  } = useCourseSupervisorContext();

  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);

  const hasUserScrolledDown = useInfiniteScroll(
    containerRef?.firstChild as HTMLDivElement,
    standardSpacing,
    false,
  ) && currentTab === TabName.PENDING_SUBMISSIONS;

  const hasUserScrolledDownCourses = useInfiniteScroll(
    containerRefCourses?.firstChild as HTMLDivElement,
    standardSpacing,
    false,
  ) && currentTab === TabName.SUPERVISOR_COURSES;

  const handleTabClick = (tabName: TabName) => {
    setCurrentTab(tabName);
  };

  useEffect(() => {
    dispatch(fetchScenariosPendingReview());
    dispatch(fetchSupervisorCourses({ page_size: 30 }));
    dispatch(fetchPendingSubmissions({ page_size: 30 }));
  }, [dispatch]);


  const onRefChange = useCallback(node => setContainerRef(node), []);
  const onRefChangeCourses = useCallback(node => setContainerRefCourses(node), []);

  const tabs = [
    {
      name: TabName.SUPERVISOR_COURSES,
      icon: ActiveIcon,
      title: t.SUPERVISOR_ADMIN_DASHBOARD.TABS.ALL(),
      color: kelp,
      count: supervisorCoursesTotalCount || 0,
    },
    {
      name: TabName.PENDING_SUBMISSIONS,
      title: t.SUPERVISOR_ADMIN_DASHBOARD.TABS.PENDING_SUBMISSIONS(),
      icon: PendingSubmissions,
      count: pendingSubmissionsCount,
      color: gray2,
    },
  ];

  const courseTableColumns: NvResponsiveTableColumn[] = [
    {
      name: t.SUPERVISOR_ADMIN_DASHBOARD.TABLE.COLUMN_HEADERS.NAME(),
      className: 'course-name-cells',
      gridWidth: '80%',
    },
    {
      name: t.SUPERVISOR_ADMIN_DASHBOARD.TABLE.COLUMN_HEADERS.MENTEES_NUMBER(),
      className: 'mentees-cells',
      gridWidth: '20%',
    },
  ];

  const tableColumns: NvResponsiveTableColumn[] = [
    {
      name: t.SUPERVISOR_ADMIN_DASHBOARD.TABLE.COLUMN_HEADERS.SCENARIO_NAME(),
      className: 'scenario-cell',
      gridWidth: '33%',
    },
    {
      name: t.SUPERVISOR_ADMIN_DASHBOARD.TABLE.COLUMN_HEADERS.NAME(),
      className: 'user-cell',
      gridWidth: '33%',
    },
    {
      name: t.SUPERVISOR_ADMIN_DASHBOARD.TABLE.COLUMN_HEADERS.PENDING_REVIEW(),
      className: 'pending-review-cells',
      gridWidth: '33%',
    },
  ];

  const headerCellTop = useMemo(() => {
    if (currentTab === TabName.PENDING_SUBMISSIONS) {
      return hasResults && !hasUserScrolledDown ? 255 : 215;
    }
    if (!pendingSubmissionsCount) {
      return 50;
    }
    return hasResultsCourses && !hasUserScrolledDownCourses ? 200 : 150;
  }, [currentTab, hasResults, hasResultsCourses, hasUserScrolledDown, hasUserScrolledDownCourses, pendingSubmissionsCount]);

  const onSelectFilter = scenario => {
    if (scenario.id === 0) {
      setPagedFetchParams({ filters: undefined });
    } else {
      setPagedFetchParams({ filters: { scenario_id: scenario.id.toString() } });
    }
    onChangeScenarioFilter(scenario);
    setIsOptionsOpen(false);
  };

  const onOptionsToggle = (isOpen: boolean) => setIsOptionsOpen(isOpen);

  const handleClearSearch = () => {
    setIsOptionsOpen(false);
    onChangeScenarioFilter();
    setPagedFetchParams({ filters: undefined });
  };

  const onClickDashboardRow = (course) => {
    let URL = `/#!/courses/${course.catalogId}/mentees/User/${currentUser.id}`;
    if (course.type === 'mentorship_program') {
      if (course.mentorshipProgramEnrollment.assignedConnections.length > 1) {
        URL = $state.href('mentoring-program-my-mentees', { programId: course.id });
      } else if (course.mentorshipProgramEnrollment.assignedConnections.length === 1) {
        URL = $state.href('mentoring-program-single-connection', {
          programId: course.id,
          connectionId: course.mentorshipProgramEnrollment.assignedConnections[0].connectionId,
        });
      } else {
        URL = $state.href('mentoring-program-root', { programId: course.id });
      }
    }
    window.location.href = URL;
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(
      window?.location.hash?.replace('#!/course-supervisor-dashboard', ''),
    );
    const tabValue = searchParams.get('tab');

    if (tabValue && TabName[tabValue.toUpperCase() as TabName]) {
      setCurrentTab(tabValue as TabName);
    }
  }, []);

  return (
    <div css={styles(headerCellTop, hasUserScrolledDown || hasUserScrolledDownCourses)}>
      <LoadingWrapper
        keepRendered={false}
        loaderType={LoaderType.PLACEHOLDER}
        isLoaded={
          !isLoadingPendingSubmissions || !isLoadingScenariosPendingReview || !isLoadingSupervisorCourses
        }
        css={loadingWrapperStyles}
      >
        <div className='dashboard-header-container'>
          {!!pendingSubmissionsCount && (
            <div className='dashboard-tabs'>
              {tabs.map(tab => (
                <ClickableContainer
                  key={tab.name}
                  className={`tab-container ${
                    currentTab === tab.name ? 'active' : ''
                  }}`}
                  onClick={() => handleTabClick(tab.name)}
                  data-qa={`course-admin-dashboard-tab-${tab.name.toLowerCase()}`}
                >
                  <Tab
                    color={tab.color}
                    count={tab.count}
                    title={tab.title}
                    tooltipText={tab.title}
                    isCompact={hasUserScrolledDown || hasUserScrolledDownCourses}
                    isSelected={currentTab === tab.name}
                    descriptionItems={[]}
                    renderImage={() => renderIcon(tab.icon)}
                  />
                </ClickableContainer>
              ))}
            </div>
          )}
          <div className='dashboard-actions'>
            {currentTab === TabName.PENDING_SUBMISSIONS && (
              <NvDropdown
                key='manual-btn'
                disabled={scenarioItems.length === 0}
                title={selectedScenario.title}
                buttonStyle={NvDropdownButtonStyle.FORM_SMALL}
                show={isOptionsOpen}
                onToggle={onOptionsToggle}
                toggleDataQa={`${config.pendo.practice.scenariosPendingSubmissions}_dropdown`}
                items={scenarioItems.map(scenario => ({
                  id: scenario.id,
                  type: 'custom',
                  customItem: (
                    <DropdownItem
                      key={scenario.id}
                      title={scenario.title}
                      counter={scenario.pendingSubmissionsCount}
                      onClick={() => onSelectFilter(scenario)}
                      selected={selectedScenario.title === scenario.title}
                      pendo-tag-name={`${config.pendo.practice.scenariosPendingSubmissions}_option_${scenario.id}`}
                    />
                  ),
                }))}
                align={NvDropdownAlign.LEFT}
              />
            )}
          </div>
        </div>
        <div className='dashboard-content'>
          {currentTab === TabName.PENDING_SUBMISSIONS && (
            <div className='dashboard-tab-content' ref={onRefChange}>
              <NvResponsiveTable<VideoPracticeSubmission, {}>
                className='course-admin-dashboard-table'
                columns={tableColumns}
                fetchData={fetchPendingSubmissions}
                fetchParams={{}}
                pagedFetchParams={pagedFetchParams}
                currentPageNameParam='page'
                currentPageSizeNameParam='page_size'
                pageSize={30}
                currentSearchQueryNameParam='text'
                rowComponent={PendingSubmissionsRow}
                rowProps={{ }}
                dataKey='id'
                cacheDataKey='id'
                cacheLookup={getStorePendingSubmissionsData}
                loadingComponent={({ rowIndex }) => (
                  <DashboardLoadingRow rowIndex={rowIndex} hideImg />
                )}
                clearSearch={handleClearSearch}
                backgroundColor='transparent'
                noResultsText={null}
                noResultsClearTextDataQA='course-supervisor-dashboard-clear-search-table'
                onResults={setHasResults}
                onRowClick={onClickPendingSubmissionRow}
              />
            </div>
          )}
          {currentTab === TabName.SUPERVISOR_COURSES && (
            <div className='dashboard-tab-content' ref={onRefChangeCourses}>
              <NvResponsiveTable<BasicMentee, {}>
                className='course-admin-dashboard-table'
                columns={courseTableColumns}
                fetchData={fetchSupervisorCourses}
                fetchParams={{}}
                pagedFetchParams={pagedFetchParams}
                currentPageNameParam='page'
                currentPageSizeNameParam='page_size'
                pageSize={30}
                currentSearchQueryNameParam='text'
                rowComponent={SupervisorCourseRow}
                rowProps={{}}
                dataKey='id'
                cacheDataKey='id'
                cacheLookup={getSupervisorCoursesData}
                loadingComponent={({ rowIndex }) => (
                  <DashboardLoadingRow rowIndex={rowIndex} hideImg />
                )}
                clearSearch={() => null}
                backgroundColor='transparent'
                noResultsText={null}
                noResultsClearTextDataQA='course-supervisor-dashboard-clear-search-table'
                onResults={setHasResultsCourses}
                onRowClick={onClickDashboardRow}
              />
            </div>
          )}
        </div>
      </LoadingWrapper>
    </div>
  );
};

export default CourseSupervisorDashboard;
