import { Fragment, useContext } from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import moment from 'moment';

import Avatar from 'athena/components/mentoring-program/avatar';
import prodPathReplace from 'shared/prod-path-rewrite';
import NvIcon from 'shared/components/nv-icon';
import {
  NvTableCellProps,
  NvTableRowProps,
} from 'shared/components/nv-responsive-table';
import { createGridStyles, standardSpacing } from 'styles/global_defaults/scaffolding';

import { RootState } from 'redux/schemas';
import { getCurrentUser } from 'redux/selectors/users';
import { BasicMentee } from 'redux/schemas/models/course';
import { AngularContext } from 'react-app';
import { coverImgStyles } from './styles';


enum OfferingTypes {
  COURSE = 'course',
  JOURNEY = 'journey',
  MENTORSHIP_PROGRAM = 'mentorship_program',
}

type BasicMenteeCellProps = NvTableCellProps<BasicMentee, unknown> & {
  course: any; // BasicMentee;
};

const Cell = ({
  children,
  rowKey,
  reactKey,
  serializedStyles,
  divProps,
  onClick,
  rowIndex,
}: any) => (
  <div
    className={rowKey}
    css={serializedStyles}
    key={reactKey}
    {...divProps}
    onClick={onClick}
    data-qa={`${rowKey}-${rowIndex}`}
    role='button'
    tabIndex='0'
  >
    {children}
  </div>
);

const CourseNameCell = (props: BasicMenteeCellProps) => {
  const { course } = props;
  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const { currentInstitutionId } = useSelector(state => state.app);
  const currentUser = useSelector(getCurrentUser);
  const institutionBrandColor = useSelector(
    state => state.models.institutions[currentInstitutionId]?.brandColor,
  );

  const goToCourseUrl = $state.href('course', {
    catalogId: course.catalogId,
  });

  let mentorshipProgramUrl;
  if (course.type === OfferingTypes.MENTORSHIP_PROGRAM) {
    if (props.course.mentorshipProgramEnrollment.assignedConnections.length > 1) {
      mentorshipProgramUrl = $state.href('mentoring-program-my-mentees', {
        programId: course.id,
      });
    } else if (props.course.mentorshipProgramEnrollment.assignedConnections.length === 1) {
      mentorshipProgramUrl = $state.href('mentoring-program-single-connection', {
        programId: course.id,
        connectionId: props.course.mentorshipProgramEnrollment.assignedConnections[0].connectionId,
      });
    } else {
      mentorshipProgramUrl = $state.href('mentoring-program-root', {
        programId: course.id,
      });
    }
  }

  let coverImage;
  if (course.type === OfferingTypes.MENTORSHIP_PROGRAM) {
    coverImage = course.headerBackground || prodPathReplace('images/mentoring-program-header-background-base-layer.png');
  } else {
    coverImage = course.thumbnail && !course.thumbnail.includes('temp.png') ? course.thumbnail : '';
  }

  return (
    <div
      className='course-name-cell'
      css={props.serializedStyles}
      key={props.reactKey}
      {...props.divProps}
    >
      <a
        href={course.type === OfferingTypes.MENTORSHIP_PROGRAM
          ? mentorshipProgramUrl
          : `${goToCourseUrl}/mentees/User/${currentUser.id}`}
        rel='noopener noreferrer'
        className='cover-image-container'
        data-offering-name={course.catalogId}
      >
        <div className='image' css={coverImgStyles(coverImage, institutionBrandColor, course.type === OfferingTypes.MENTORSHIP_PROGRAM)}>
          {course.type === OfferingTypes.MENTORSHIP_PROGRAM && (
            <div className='mentor-plus-diamond'>
              <NvIcon icon='mentor-plus' size='smallest' className='text-white' />
            </div>
          )}
        </div>
        <div>
          <p className='title'>{course.name}</p>
          <p className='course-catalog-id'>{course.catalogId}</p>
        </div>
      </a>
    </div>
  );
};

const MenteesCell = (props: BasicMenteeCellProps) => {
  if (props.course.type === OfferingTypes.MENTORSHIP_PROGRAM) {
    if (props.course.mentorshipProgramEnrollment.assignedConnections.length === 0
        || !(props.course.matchAnnouncementDate && moment().isAfter(moment(props.course.matchAnnouncementDate)))) {
      return (
        <Cell {...props}>
          {t.SUPERVISOR_ADMIN_DASHBOARD.TABLE.PENDING_MENTEES({ MenteeAlias: props.course.programAlias.mentee.singularizedTitleized })}
        </Cell>
      );
    }

    if (props.course.mentorshipProgramEnrollment.assignedConnections.length === 1) {
      return (
        <Cell {...props}>
          <Avatar user={props.course.mentorshipProgramEnrollment.assignedConnections[0].assignedEnrollment.user} className='mr-1 avatar' />
          {t.USERS.FULL_NAME(
            props.course.mentorshipProgramEnrollment.assignedConnections[0].assignedEnrollment.user.firstName,
            props.course.mentorshipProgramEnrollment.assignedConnections[0].assignedEnrollment.user.lastName,
          )}
        </Cell>
      );
    }

    return (
      <Cell {...props}>{props.course.mentorshipProgramEnrollment.assignedConnections.length} {props.course.programAlias.mentee.pluralizedTitleized}</Cell>
    );
  }

  const { menteeAlias } = props.course;
  const suffix = (!props.course.totalNoOfMentees || props.course.totalNoOfMentees > 1) ? menteeAlias.pluralizedTitleized : menteeAlias.singularizedTitleized;
  return (
    <Cell {...props}>{props.course.totalNoOfMentees} {suffix}</Cell>
  );
};

const createCellProps = (
  props: NvTableRowProps<BasicMentee, {}>,
  key: string,
  i: number,
  rowIndex: number,
) => ({
  ...props,
  reactKey: `${rowIndex}-${i + 1}`,
  serializedStyles: css`
    .avatar {
      height: ${standardSpacing}px;
      width: ${standardSpacing}px;
    }
  `,
  rowKey: key,
  divProps: {
    style: createGridStyles(i + 1, rowIndex, i + 2, rowIndex + 1),
  },
  course: props.data,
});

const SupervisorCourseRow = (props: NvTableRowProps<BasicMentee, {}>) => {
  const cells: [string, (props: any) => JSX.Element][] = [
    ['course-name-cell', CourseNameCell],
    ['mentees-cell', MenteesCell],
  ];

  return (
    <Fragment>
      {cells.map((Item, i) => {
        const [key, Component] = Item;
        const cellProps = createCellProps(props, key, i, props.rowIndex);
        return <Component key={key} {...cellProps} />;
      })}
    </Fragment>
  );
};

export default SupervisorCourseRow;
