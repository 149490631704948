import t from 'react-translate';
import { css } from '@emotion/react';
import { novoEdBlue } from 'athena/styles/colors';
import { gray2, gray4 } from 'styles/global_defaults/colors';
import { ReactComponent as UncheckedBox } from 'styles/icons/unchecked-box.svg';
import { ActionItemStatus } from 'redux/schemas/models/mentoring-program-action-items';
import { useFormContext } from 'react-hook-form';
import NvTooltip from 'shared/components/nv-tooltip';
import { config } from '@config/pendo.config.json';
import CheckedBox from './animated-checkmark';
import { CHECKBOX_SIZE } from './constants';
import { tooltipZIndex } from '../constants';

type ActionStatusProps = {
  actionItemId: string | number;
  status: ActionItemStatus;
};

const styles = css`
  cursor: pointer;

  &.unchecked {
    &:hover {
      color: ${gray2};
    }
  }
`;

const ActionStatus = ({
  actionItemId,
  status = ActionItemStatus.INCOMPLETE,
}: ActionStatusProps) => {
  const { setValue } = useFormContext();
  const dataQATag = config.pendo.athena.mentorshipProgram.participantHome.actionItems.item
    .editActionItemStatus;
  const translationBase = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.ACTION_ITEMS;

  const toggleStatus = () => {
    const newValue = status === ActionItemStatus.COMPLETED
      ? ActionItemStatus.INCOMPLETE
      : ActionItemStatus.COMPLETED;
    setValue('status', newValue);
  };

  const commonProps = {
    css: styles,
    width: CHECKBOX_SIZE,
    height: CHECKBOX_SIZE,
    onClick: toggleStatus,
  };
  const statusIndicator = {
    [ActionItemStatus.INCOMPLETE]: {
      actionStatus: (
        <UncheckedBox {...commonProps} className='unchecked' color={gray4} />
      ),
      tooltip: translationBase.ITEM.TOOLTIP.MARK_COMPLETE(),
      ariaChecked: false,
    },
    [ActionItemStatus.COMPLETED]: {
      actionStatus: <CheckedBox {...commonProps} color={novoEdBlue} />,
      tooltip: translationBase.ITEM.TOOLTIP.MARK_UNCOMPLETE(),
      ariaChecked: true,
    },
  };

  const { actionStatus, tooltip, ariaChecked } = statusIndicator[status];

  return (
    <NvTooltip placement='top' text={tooltip} zIndex={tooltipZIndex}>
      <div
        tabIndex={0}
        role='checkbox'
        aria-checked={ariaChecked}
        aria-label={translationBase.ARIA_LABEL.ACTION_ITEM_STATUS()}
        className='d-flex'
        data-qa={dataQATag}
        data-qa-id={`${dataQATag}-${actionItemId}-${status}`}
      >
        {actionStatus}
      </div>
    </NvTooltip>
  );
};

export default ActionStatus;
