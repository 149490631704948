import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AngularContext } from 'react-app';
import t from 'react-translate';
import moment from 'moment';
import { useAppDispatch } from 'redux/store';
import { addCurrentCourseMyLearning, getFlyoutCurrentLearning } from 'redux/actions/courses';
import { BasicEnrollment } from 'redux/schemas/models/course';
import { MentoringProgramEnrollment, MentoringProgramRole } from 'redux/schemas/models/mentoring-program-enrollments';
import { CombinedCourse } from 'redux/schemas';
import { LoaderType } from 'shared/components/loading-placeholder';
import { getCurrentUserEnrollments } from 'redux/selectors/users';
import { Enrollment } from 'redux/schemas/models/my-account';
import { RolesService } from 'institutions/services/roles-service';
import { useInfiniteLoading } from 'shared/hooks/use-infinite-loading';
import prodPathReplace from 'shared/prod-path-rewrite';
import FlyoutCourseCard, { VariantType } from './flyout-course-card';
import FlyoutContainer from './flyout-container';
import { AllLoaded, EmptyContent, LoadingBar } from './loading-placeholders';


enum OfferingTypes {
  COURSE = 'course',
  JOURNEY = 'journey',
  MENTORSHIP_PROGRAM = 'mentorship_program',
}

const MyLearningPanel = () => {
  const dispatch = useAppDispatch();
  const { injectServices } = useContext(AngularContext);
  const [$state, CurrentUserManager, InteroperabilityRoutes] = injectServices(['$state', 'CurrentUserManager', 'InteroperabilityRoutes']);
  const { currentCatalogId, currentInstitutionId, currentCourseId } = useSelector((state) => state.app);
  const institutionBrandColor = useSelector((state) => state.models.institutions[currentInstitutionId]?.brandColor);
  const isAdmin = !!CurrentUserManager.isAdmin();
  const allCourses = useSelector((state) => state.models.courses);
  const hasCurrentCourse = currentCatalogId && $state.includes('course-wrapper');
  const currentUserEnrollments: Enrollment[] = useSelector(getCurrentUserEnrollments);
  const { programId: currentProgramId } = $state.params;

  const {
    items,
    noItems,
    loading,
    loadingMore,
    hasMore,
    ids,
  } = useInfiniteLoading(
    'myLearning',
    'basicEnrollments',
    { page_size: 30 },
    getFlyoutCurrentLearning,
  );
  const enrollments = items as any[]; // BasicEnrollment[];

  useEffect(() => {
    const currentCourse: CombinedCourse = allCourses[currentCatalogId];
    const isMentorInCurrentCourse = currentUserEnrollments.some(enrollment => RolesService.isMentor(enrollment.roles));
    // added the condition of currentCourseId, as, in the admin dashboard if you select the options menu,
    // the currentCatalogId exists, but you are not on a course.
    if (currentCourse && !currentCourse.isJourney && !isMentorInCurrentCourse && !currentCourse.isContentManagementCollection && currentCourseId) {
      const currentEnrollment: BasicEnrollment = {
        id: currentCourse.id,
        catalogId: currentCatalogId,
        name: currentCourse.name,
        thumbnail: currentCourse.thumbnail,
        closeDate: currentCourse.closeDate,
        headerColor: currentCourse.headerColor,
        inJourneys: currentCourse.inJourneys,
        isPrimary: currentCourse.isPrimary,
        isCohort: currentCourse.isCohort,
      };
      dispatch(addCurrentCourseMyLearning(currentEnrollment));
    }
  }, [hasCurrentCourse]);

  return (
    <FlyoutContainer title={t.LHS.MY_LEARNING()} isHeaderSticky>
      <>
        {loading && <LoadingBar loaderType={LoaderType.FULL} />}
        {loadingMore && <LoadingBar loaderType={LoaderType.HALF} />}
        { !loading && noItems
          ? <EmptyContent text={t.LHS.NO_COURSES()} />
          : ids.map((id, index) => {
            const {
              type,
              endDate,
              closeDate,
              inJourneys,
              mentorshipProgramEnrollment,
              programAlias,
              catalogId,
              name,
              headerBackground,
              thumbnail,
              headerColor,
              isCohort,
              isPrimary,
            } = enrollments[id];

            const isMentorshipProgram = type === OfferingTypes.MENTORSHIP_PROGRAM;

            const isClosed = isMentorshipProgram
              ? moment(endDate).isBefore(moment())
              : moment(closeDate).isBefore(moment());
            const badge = {
              label: '',
              variant: '',
            };
            if (isMentorshipProgram) {
              if (mentorshipProgramEnrollment.role.name === MentoringProgramRole.MENTORSHIP_PROGRAM_ADMIN) {
                badge.label = t.LHS.COURSE_CARD.ADMIN();
                badge.variant = 'admin';
              }
              if (mentorshipProgramEnrollment.role.name === MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR) {
                badge.label = programAlias.mentor?.singularizedTitleized;
                badge.variant = 'mentor';
              }
            } else if (isAdmin) {
              badge.label = t.LHS.COURSE_CARD.ADMIN();
              badge.variant = 'admin';
            }
            if (inJourneys?.length) {
              badge.label = inJourneys[0].name;
              badge.variant = 'journey';
            }
            let link;
            if (isMentorshipProgram) {
              link = $state.href('mentoring-program-root', { programId: id });
              if (mentorshipProgramEnrollment.assignedConnections.length > 1) {
                link = $state.href('mentoring-program-my-mentees', { programId: id });
              }
              if (mentorshipProgramEnrollment.assignedConnections.length === 1) {
                link = $state.href('mentoring-program-single-connection', {
                  programId: id,
                  connectionId: mentorshipProgramEnrollment.assignedConnections[0].connectionId,
                });
              }
            }
            if (catalogId) {
              link = InteroperabilityRoutes.courseHomePath(allCourses[catalogId]);
            }
            const isCurrent = isMentorshipProgram
              ? `${id}` === currentProgramId
              : (currentCatalogId === catalogId) && $state.includes('course-wrapper');

            return (
              <FlyoutCourseCard
                index={index}
                label={name}
                link={link}
                isCurrent={isCurrent}
                key={id}
                image={isMentorshipProgram ? (headerBackground || prodPathReplace('images/mentoring-program-header-background-base-layer.png')) : thumbnail}
                bgcolor={headerColor || institutionBrandColor}
                isClosed={isClosed}
                isAdmin={isAdmin}
                tooltip={catalogId}
                badgeLabel={badge.label}
                variant={badge.variant as VariantType}
                badgeUrl={inJourneys?.length ? InteroperabilityRoutes.journeyHomePathFromId(allCourses[catalogId], inJourneys[0].catalogId) : ''}
                isCohort={isCohort}
                isPrimary={isPrimary}
                isMentorshipProgram={isMentorshipProgram}
              />
            );
          })}
        {!hasMore && !noItems && <AllLoaded />}
      </>
    </FlyoutContainer>
  );
};

export default MyLearningPanel;
